.blog {
    padding: 43px 0 72px 0;
    background-color: #555555;

    a {
        text-decoration: none;
    }

    h3 {
        font-family: 'Roboto Flex';
        font-size: 14px;
        color: #F4B344;
        position: relative;
        text-align: center;
        padding: 8px 0;
        margin-bottom: -6px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            margin: 0 auto;
            height: 2px;
            width: 52px;
            background-color: #F4B344;
        }
    }

    h2 {
        font-family: 'Encode Sans';
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        text-align: center;
        margin-bottom: 61px;
    }

    .post-destaque {
        a {
            text-decoration: none;
        }

        .img-destaque {
            width: 100%;
            max-width: 509px;
            height: 297px;
            border-radius: 20px;
            margin-bottom: 20px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
                object-fit: cover;
            }
        }

        h4 {
            font-family: 'Encode Sans';
            font-weight: 800;
            color: #ffffff;
            font-size: 26px;
            max-width: 499px;
            margin-left: 24px;
            margin-bottom: 9px;
        }

        p {
            font-family: 'Roboto Flex';
            font-size: 18px;
            color: #ffffff;
            max-width: 499px;
            margin-left: 24px;
            line-height: 24px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: -22px;
                left: 0;
                width: 93px;
                height: 3px;
                background-color: #F4B344;
            }
        }
    }

    .posts {
        a {
            text-decoration: none;
        }

        .post {
            margin-bottom: 65px;
            margin-left: 18px;

            h4 {
                font-family: 'Encode Sans';
                font-size: 22px;
                color: #ffffff;
                font-weight: 800;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    width: 93px;
                    height: 3px;
                    background-color: #F4B344;
                }
            }

            .img-post {
                width: 100%;
                max-width: 208px;
                height: 121px;
                border-radius: 10px;
                float: right;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
        }
    }
}
