body {
    //background: url('../img/home/_home-01.png') top center no-repeat;
    position: relative;
}

img {
    max-width: 100%;
}

@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1177px !important;
    }
}

.navbar {
    padding: 20px 0 !important;
    position: absolute !important;
    width: 100%;
    z-index: 99;

    @media(max-width: 991px) {
        padding: 5px 0 !important;
        position: relative !important;
        background-color: #555555;
    }
}

.navbar-nav {
    gap: 12px;
}

.navbar-nav .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}

.navbar-brand {
    padding: 20px 0;
}

.navbar-expand-lg .navbar-collapse {
    align-items: center;
    justify-content: flex-end;
}

.nav-link,
.nav-link {
    transition: .3s ease-in;
}

.nav-link:focus,
.nav-link:hover {
    transition: .3s ease-in;
    color: #F4B344 !important;
}

@media(max-width: 991px) {
    .navbar-toggler {
        background: url('../img/menu-mobile.png') center center no-repeat;
        background-size: cover;
        margin-right: 10px;
    }

    .navbar-toggler:focus {
        box-shadow: none !important;
    }

    .navbar-nav {
        text-align: center;

        .nav-item {
            padding-bottom: 15px;
            border-bottom: solid 1px #FFFFFF;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.logo {
    max-width: 238px;

    @media(max-width: 991px) {
        max-width: 150px;
    }
}
