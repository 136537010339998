#blog-listagem {
    background: url('../img/home/background-home.png') top center no-repeat;

    #carouselHome {
        position: relative;

        .carousel-item {
            width: 100%;
            height: 347px;

            @media(max-width: 991px) {
                height: auto;
            }

            img {
                position: absolute;
                width: 100%;
                top: 0;
                right: -33.3%;
                z-index: 1;
                border-radius: 0px 0px 0px 68px;

                @media(max-width: 991px) {
                    position: relative;
                    height: 147px;
                }
            }

            .carousel-caption {
                right: inherit;
                left: 0;
                top: 135px;
                text-align: left;
                z-index: 3;
                max-width: 310px;

                @media(max-width: 991px) {
                    position: relative;
                    top: 0;
                    max-width: 100%;
                    text-align: center;
                }

                h2 {
                    font-family: 'Encode Sans';
                    font-size: 30.15px;
                    color: #636362;
                    font-weight: 800;
                    margin-bottom: 13px;
                }

                p {
                    font-family: 'Roboto Flex';
                    font-size: 14px;
                    color: #555555;
                    margin-bottom: 21px;
                    line-height: 20px;
                }

                .btn-banner {
                    display: inline-block;
                    text-decoration: none;
                    font-weight: 800;
                    font-size: 12px;
                    color: #555555;
                    background-color: #D0D1D3;
                    padding: 12px 24px;
                    border-radius: 20px;
                    transition: .3s ease-in;

                    &:hover {
                        transition: .3s ease-in;
                        background-color: #F4B344;
                        color: #ffffff;
                    }
                }
            }
        }

        .carousel-indicators [data-bs-target] {
            width: 13px;
            height: 13px;
            border-radius: 100%;
            opacity: 1;
            background-color: #F4B344;
        }

        .carousel-indicators {
            bottom: -43px;
            gap: 5px;
        }

        .carousel-indicators .active {
            background-color: #555555;
        }
    }

    .alugar-comprar {
        margin-top: 87px;
        margin-bottom: 60px;

        @media(max-width: 991px) {
            margin-top: 37px;
        }

        h3 {
            font-family: 'Roboto Flex';
            font-size: 14px;
            color: #EAAF0E;
            margin-bottom: 0;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: -60px;
                top: 8px;
                width: 52px;
                height: 2.5px;
                background-color: #EAAF0E;
            }
        }

        h2 {
            font-family: 'Encode Sans';
            font-size: 30.14px;
            color: #555555;
            font-weight: 800;
        }

        .filtros {
            display: flex;
            align-items: center;
            margin-top: 18px;

            @media(max-width: 991px) {
                display: block;
            }

            p {
                font-family: 'Roboto Flex';
                font-size: 14px;
                color: #000000;
                margin-bottom: 0;
            }

            select,
            option {
                font-family: "Encode Sans";
                font-size: 13.82px;
                color: #555555;
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 800;
                padding: 11px 50px;
                border-radius: 30px;
                background-color: #D0D1D3;
                cursor: pointer;
                border: none;
            }

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background: #D0D1D3 url('../img/seta-select.png') 93.5% 50% no-repeat;
                background-size: 21px;

                @media(max-width: 991px) {
                    text-align: center;
                }
            }

            .filter2 {
                display: flex;
                gap: 35px;
                padding-left: 2rem;

                @media(max-width: 991px) {
                    display: inline-grid;
                    gap: 15px;
                    width: 100%;
                    padding-right: 2rem;
                }

                a {
                    font-weight: 800;
                    font-size: 13px;
                    color: #F4B344;
                    background-color: #555555;
                    padding: 11px 41px;
                    border-radius: 30px;
                    position: relative;
                    transition: .3s ease-in;
                    text-decoration: none;
                    cursor: pointer;

                    @media(max-width: 991px) {
                        text-align: center;
                    }

                    &:hover {
                        transition: .3s ease-in;
                        background-color: #F4B344;
                        color: #555555;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: -9px;
                        left: -17px;
                        width: 2px;
                        height: 63px;
                        background-color: #555555;

                        @media(max-width: 991px) {
                            display: none;
                        }
                    }
                }
            }
        }

        ul.filter.clearfix {
            display: flex;
            gap: 5px;

            @media(max-width: 991px) {
                max-width: 100%;
                overflow: auto;
            }

            li {
                font-family: 'Encode Sans';
                font-size: 13.82px;
                color: #555555;
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 800;
                padding: 11px 27px;
                border-radius: 30px;
                background-color: #D0D1D3;
                cursor: pointer;
                transition: .3s ease-in;

                svg {
                    width: 30px;
                    height: 30px;
                }

                &:hover,
                &.active {
                    background-color: #555555;
                    color: #F4B344;
                    transition: .3s ease-in;

                    svg path {
                        fill: #F4B344;
                    }
                }

                label {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    input {
                        display: none;
                    }
                }
            }
        }
    }

    .lista-imoveis {
        padding-bottom: 80px;

        a {
            display: inline-block;
            text-decoration: none;

            @media(max-width: 991px) {
                width: 100%;
            }
        }

        .card-imovel {
            width: 100%;
            max-width: 100%;
            margin-bottom: 70px;

            @media(max-width: 991px) {
                max-width: 100%;
                margin-bottom: 30px;
            }

            .img-imovel {
                width: 100%;
                height: 246px;
                border-radius: 30px;
                margin-bottom: 25px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 30px;
                }
            }

            h2 {
                font-weight: 800;
                color: #555555;
                font-size: 22px;
                margin-bottom: 0px;
            }

            .preco {
                font-size: 20px;
                color: #000000;
            }

            p {
                font-size: 12px;
                color: #555555;
                margin-bottom: 5px;
            }

            .bairro {
                font-size: 13px;
                color: #555555;

                span {
                    font-size: 13px;
                    color: #F4B344;
                }
            }

            .descricao-imovel {
                max-width: 100%;
                margin-left: 20px;
                margin-right: 20px;
                margin-bottom: 20px;
            }
        }
    }


    .box {
        float: left;
        overflow: hidden;
        background: #00000094;
        position: absolute;
        width: 100%;
        min-height: 100vh;
        top: 0;
        right: 0;
        z-index: 999;
    }

    .box-inner {
        width: 700px;
        float: right;
        right: 0;
        background: #eaeaeacc;
        min-height: 100vh;
        padding: 0 40px 50px 40px;

        @media (max-width: 1100px) {
            width: 100%;
            min-height: 100vh;
        }

        .topo-menu {
            position: relative;
            margin: 50px 50px;

            .slide-toggle {
                cursor: pointer;

                img {
                    max-width: 33px;
                    float: right;
                }
            }
        }

        .filtro-menu {
            clear: both;

            h3 {
                font-size: 30px;
                font-weight: 400;
                color: #000000;
                margin-bottom: 40px;

                strong {
                    font-size: 33px;
                    font-weight: 600;
                }
            }

            h4 {
                font-weight: 900;
                font-size: 21px;
                color: #555555;
                margin: 30px 0;
            }

            label {
                width: 100%;
                font-size: 10px;
                color: #000000;
                margin-bottom: 5px;
            }

            input {
                font-family: 'Roboto Flex';
                font-size: 14px;
                color: #555555;
                padding: 10px;
                border-radius: 30px;
                border: solid 1px #555555;
            }

            button {
                font-weight: 900;
                font-size: 13px;
                color: #F4B344;
                display: flex;
                gap: 15px;
                align-items: center;
                justify-content: space-between;
                padding: 8px 21px;
                border-radius: 30px;
                background-color: #555555;
                margin-top: 30px;
                margin-left: -20px;

                img {
                    max-width: 30px;
                }
            }

            .opcao-tipo {
                font-size: 14px;
                color: #000000;
                display: flex;
                gap: 10px;
                align-items: center;
                margin-bottom: 5px;
            }

            .bloco-busca {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: -20px;
                    top: 0;
                    width: 4px;
                    height: 100%;
                    background-color: #F4B344;
                }
            }
        }
    }
}
