/*
    SUMMARY
        0 - Set Transitions
        1 - Hovers
        2 - Enter Animations
        3 - Keyframes

*/
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
img.zoom--hover,
img.up--hover,
img.arrow--hover {
  transition: 0.4s;
}

img.zoom--hover:hover {
  transform: scale(1.05);
}

img.up--hover:hover {
  transform: translateY(-30px);
}

.double {
  display: grid !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.double__img {
  grid-area: 1/1/-1/-1;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.enter-image-style {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
}

a:hover img.arrow--hover,
button:hover img.arrow--hover {
  transform: translateX(10px);
}

.animated-element[data-animation] {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

.scroll-down-animation {
  animation-name: scrollDown;
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

[data-animation] {
  opacity: 0 !important;
  transition: 1s;
}

[data-animation=right] {
  transform: translateX(-50px);
}

[data-animation=left] {
  transform: translateX(50px);
}
@media (max-width: 999px) {
  [data-animation=left] {
    transform: translateX(-50px) !important;
  }
}

[data-animation=up] {
  transform: translateY(50px);
}

[data-animation=down] {
  transform: translateY(-50px);
}

[data-sequencial] [data-sequencial-stagger] {
  opacity: 0;
}
[data-sequencial] [data-sequencial-stagger=right] {
  transform: translateX(-50px);
}
[data-sequencial] [data-sequencial-stagger=left] {
  transform: translateX(50px);
}
[data-sequencial] [data-sequencial-stagger=up] {
  transform: translateY(50px);
}
[data-sequencial] [data-sequencial-stagger=down] {
  transform: translateY(50px);
}

[data-animation-banner] {
  opacity: 0 !important;
  transition: 1s;
}
[data-animation-banner].animated-element {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

body:not(.no-js) .reveal {
  position: relative;
  overflow: hidden;
}

body:not(.no-js) .image-wrap {
  transition: 1s ease-out;
  transition-delay: 0.2s;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
}

body:not(.no-js) .image-wrap img {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .image-wrap .enter-image-style {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .animating .image-wrap {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewY(0);
}

body:not(.no-js) .animating img {
  transform: scale(1);
  transition: 4s ease-out;
}

body:not(.no-js) .animating .enter-image-style {
  transform: scale(1);
  transition: 4s ease-out;
}

@keyframes scrollDown {
  0% {
    transform: translate(13.062px, 20.843px);
  }
  50% {
    transform: translate(13.062px, 9.843px);
  }
  100% {
    transform: translate(13.062px, 20.843px);
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  font-weight: normal;
  margin: 0;
}

:focus,
button:focus,
a:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
}
a:focus, a:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

form {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}
button:focus, button:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

@custom-media --desktop_big (width >= 1920px)

@custom-media --desktop (width <= 1399px)

@custom-media --desktop_min (width <= 1199px)

@custom-media --tablet_big (width <= 999px)

@custom-media --tablet (width <= 799px)

@custom-media --mobile_big (width <= 599px)

@custom-media --mobile (width <= 499px);
/*  */
/*------------- Hover background gradient --------------*/
@property --myColor1 {
  syntax: "<color>";
  initial-value: #FCA32D;
  inherits: false;
}
@property --myColor2 {
  syntax: "<color>";
  initial-value: #FF6196;
  inherits: false;
}
.button-hover-linearGradient {
  background-image: linear-gradient(105deg, var(--myColor1) 7%, var(--myColor2) 78%);
  transition: --myColor1 0.7s, --myColor2 0.7s;
}
.button-hover-linearGradient:hover {
  --myColor1: #ff933c;
  --myColor2: #ff933c;
}

.button-hover-linearGradient-others-browsers {
  background-image: linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -o-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -moz-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
}

/*@font-face {
	font-family: 'Branding-Medium';
	src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
	url('../fonts/Branding/Branding-Medium.woff') format('woff'),
	url('../fonts/Branding/Branding-Medium.eot') format('eot'),
	url('../fonts/Branding/Branding-Medium.svg') format('svg');
}*/
html {
  font-size: 62.5%;
}

* {
  font-family: "Encode Sans";
}

.form-group {
  position: relative;
  margin: 0;
}

input.error, textarea.error, select.error {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

label.error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

.grecaptcha-badge {
  display: none !important;
}

body {
  position: relative;
}

img {
  max-width: 100%;
}

@media (min-width: 1400px) {
  .container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1177px !important;
  }
}
.navbar {
  padding: 20px 0 !important;
  position: absolute !important;
  width: 100%;
  z-index: 99;
}
@media (max-width: 991px) {
  .navbar {
    padding: 5px 0 !important;
    position: relative !important;
    background-color: #555555;
  }
}

.navbar-nav {
  gap: 12px;
}

.navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
}

.navbar-brand {
  padding: 20px 0;
}

.navbar-expand-lg .navbar-collapse {
  align-items: center;
  justify-content: flex-end;
}

.nav-link,
.nav-link {
  transition: 0.3s ease-in;
}

.nav-link:focus,
.nav-link:hover {
  transition: 0.3s ease-in;
  color: #F4B344 !important;
}

@media (max-width: 991px) {
  .navbar-toggler {
    background: url("../img/menu-mobile.png") center center no-repeat;
    background-size: cover;
    margin-right: 10px;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .navbar-nav {
    text-align: center;
  }
  .navbar-nav .nav-item {
    padding-bottom: 15px;
    border-bottom: solid 1px #FFFFFF;
  }
  .navbar-nav .nav-item:last-child {
    border-bottom: none;
  }
}
.logo {
  max-width: 238px;
}
@media (max-width: 991px) {
  .logo {
    max-width: 150px;
  }
}

footer {
  padding: 73px 0;
  background-color: #E0E0E0;
}
@media (max-width: 991px) {
  footer {
    padding: 50px 0;
    text-align: center;
  }
}
footer .logo-footer {
  max-width: 140px;
}
@media (max-width: 991px) {
  footer .logo-footer {
    margin-bottom: 30px;
  }
}
footer .parte1 {
  padding-bottom: 25px;
}
footer .contatos {
  display: grid;
  gap: 12px;
}
@media (max-width: 991px) {
  footer .contatos {
    margin-bottom: 30px;
  }
}
footer .contatos a {
  display: inline-block;
  text-decoration: none;
  font-family: "Encode Sans";
  font-size: 14px;
  color: #555555;
  font-weight: 400;
  transition: 0.3s ease-in;
}
footer .contatos a:hover {
  transition: 0.3s ease-in;
  color: #000000;
}
footer .links {
  display: grid;
  gap: 12px;
}
@media (max-width: 991px) {
  footer .links {
    margin-bottom: 30px;
  }
}
footer .links a {
  display: inline-block;
  text-decoration: none;
  font-family: "Encode Sans";
  font-size: 16px;
  color: #555555;
  font-weight: 500;
  transition: 0.3s ease-in;
}
footer .links a:hover {
  transition: 0.3s ease-in;
  color: #F4B344;
}
footer p {
  font-family: "Encode Sans";
  font-size: 14px;
  color: #555555;
}

#modal-zoom-images {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(0);
  opacity: 0;
  transition: 0.4s;
  z-index: -100;
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal-zoom-images.activate-modal {
  opacity: 1;
  transform: scale(1);
  z-index: 10000;
}
#modal-zoom-images #btn-close__zoom-modal {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  #modal-zoom-images #btn-close__zoom-modal {
    top: 10px;
    right: 10px;
  }
}
#modal-zoom-images #btn-close__zoom-modal i {
  font-size: 25px;
  color: rgb(226, 226, 226);
}
#modal-zoom-images #image__zoom-modal {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}
@media (max-width: 599px) {
  #modal-zoom-images #image__zoom-modal {
    width: 90vw;
  }
}

.images--zoom {
  cursor: pointer;
}

.show--error input {
  color: red !important;
  border: 1px solid red !important;
}
.show--error input::placeholder {
  color: red !important;
}
.show--error .error--message {
  display: block;
  color: red !important;
}

.error--message {
  display: none;
}

.error-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.error-message-forms.open-modal {
  display: flex;
}
.error-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 30px 50px;
  gap: 20px;
}
.error-message-forms--container #btn--close-error-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.error-message-forms--container #btn--close-error-message ion-icon {
  font-size: 3.4rem;
  color: 900;
}
.error-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.error-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: 900;
  font-weight: 400;
  font-family: "";
  text-align: center;
}
.error-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .error-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .error-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .error-message-forms--container #btn--close-error-message img {
    width: 20px;
  }
  .error-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .error-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .error-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.success-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.success-message-forms.open-modal {
  display: flex;
}
.success-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 30px 50px;
  gap: 20px;
}
.success-message-forms--container #btn--close-success-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.success-message-forms--container #btn--close-success-message ion-icon {
  font-size: 3.4rem;
  color: 900;
}
.success-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.success-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: 900;
  font-weight: 400;
  font-family: "";
  text-align: center;
}
.success-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .success-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .success-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .success-message-forms--container #btn--close-success-message img {
    width: 20px;
  }
  .success-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .success-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .success-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.loading-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.loading-forms.open-modal {
  display: flex;
}
.loading-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vh;
  clip-path: polygon(0 8%, 72% 8%, 75% 0, 100% 0, 100% 100%, 19% 100%, 16% 92%, 0 92%);
}
.loading-forms .custom-loader {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: conic-gradient(rgba(0, 0, 0, 0) 10%, 900);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@media (max-width: 799px) {
  .loading-forms--container {
    width: 95%;
    padding: 0 !important;
    height: 200px;
  }
  .loading-forms .custom-loader {
    width: 40px;
    height: 40px;
  }
}

.blog {
  padding: 43px 0 72px 0;
  background-color: #555555;
}
.blog a {
  text-decoration: none;
}
.blog h3 {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #F4B344;
  position: relative;
  text-align: center;
  padding: 8px 0;
  margin-bottom: -6px;
}
.blog h3::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 2px;
  width: 52px;
  background-color: #F4B344;
}
.blog h2 {
  font-family: "Encode Sans";
  font-size: 30px;
  font-weight: 800;
  color: #ffffff;
  text-align: center;
  margin-bottom: 61px;
}
.blog .post-destaque a {
  text-decoration: none;
}
.blog .post-destaque .img-destaque {
  width: 100%;
  max-width: 509px;
  height: 297px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.blog .post-destaque .img-destaque img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}
.blog .post-destaque h4 {
  font-family: "Encode Sans";
  font-weight: 800;
  color: #ffffff;
  font-size: 26px;
  max-width: 499px;
  margin-left: 24px;
  margin-bottom: 9px;
}
.blog .post-destaque p {
  font-family: "Roboto Flex";
  font-size: 18px;
  color: #ffffff;
  max-width: 499px;
  margin-left: 24px;
  line-height: 24px;
  position: relative;
}
.blog .post-destaque p::after {
  content: "";
  position: absolute;
  bottom: -22px;
  left: 0;
  width: 93px;
  height: 3px;
  background-color: #F4B344;
}
.blog .posts a {
  text-decoration: none;
}
.blog .posts .post {
  margin-bottom: 65px;
  margin-left: 18px;
}
.blog .posts .post h4 {
  font-family: "Encode Sans";
  font-size: 22px;
  color: #ffffff;
  font-weight: 800;
  position: relative;
}
.blog .posts .post h4::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 93px;
  height: 3px;
  background-color: #F4B344;
}
.blog .posts .post .img-post {
  width: 100%;
  max-width: 208px;
  height: 121px;
  border-radius: 10px;
  float: right;
}
.blog .posts .post .img-post img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.contato {
  padding: 60px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 991px) {
  .contato {
    background-image: none !important;
  }
}
.contato h2 {
  font-family: "Encode Sans";
  font-size: 28px;
  color: #141414;
  position: relative;
  font-weight: 800;
  margin-left: 30px;
}
.contato h2::after {
  content: "";
  position: absolute;
  top: -12px;
  left: 0;
  height: 3px;
  width: 76px;
  background-color: #141414;
}
.contato p {
  font-family: "Encode Sans";
  font-size: 24px;
  color: #555555;
  margin-left: 30px;
  line-height: 27px;
}
.contato form {
  margin-top: 30px;
}
@media (max-width: 991px) {
  .contato form {
    margin: 30px 30px 0 30px;
  }
}
.contato form .form-control,
.contato form label,
.contato form textarea {
  width: 100%;
}
.contato form label {
  font-family: "Encode Sans";
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin-left: 32px;
  margin-bottom: 3px;
}
@media (max-width: 991px) {
  .contato form label {
    max-width: 100%;
    margin-left: 0;
  }
}
.contato form .form-check label {
  margin-left: 0 !important;
}
.contato form .form-check .form-check-input {
  margin-top: 6px;
}
.contato form .form-control,
.contato form textarea,
.contato form .form-control::placeholder {
  font-family: "Montserrat";
  font-size: 13px;
  color: #777777;
}
.contato form .form-control,
.contato form textarea {
  padding: 8px 32px;
  border: none;
  border-radius: 30px;
  margin-bottom: 20px;
  background-color: #FFF3EA;
}
@media (max-width: 991px) {
  .contato form .form-control,
.contato form textarea {
    background-color: #e0dfdf;
  }
}
.contato form textarea {
  min-height: 100px;
}
.contato form button {
  font-family: "Encode Sans";
  font-size: 13px;
  font-weight: 800;
  color: #F4B344;
  background-color: #555555;
  border-radius: 30px;
  padding: 12px 47px;
  float: right;
  transition: 0.3s ease-in;
}
.contato form button:hover {
  transition: 0.3s ease-in;
  background-color: #353434;
}

#page404 .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
}
#page404 .title .sad {
  transform: rotate(90deg);
  font-size: 60px;
}

#home {
  background: url("../img/home/background-home.png") top center no-repeat;
  background-size: contain;
}
#home #carouselHome {
  position: relative;
}
#home #carouselHome .carousel-item {
  width: 100%;
  height: 532px;
}
@media (max-width: 991px) {
  #home #carouselHome .carousel-item {
    height: auto;
  }
}
#home #carouselHome .carousel-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: -33.3%;
  z-index: 1;
  border-radius: 0px 0px 0px 68px;
}
@media (max-width: 991px) {
  #home #carouselHome .carousel-item img {
    position: relative;
    width: 100%;
    height: 305px;
    top: 0;
    right: 1;
  }
}
#home #carouselHome .carousel-item .carousel-caption {
  right: inherit;
  left: 0;
  top: 135px;
  text-align: left;
  z-index: 3;
  max-width: 310px;
}
@media (max-width: 991px) {
  #home #carouselHome .carousel-item .carousel-caption {
    position: relative;
    top: 0;
    padding-bottom: 30px;
    max-width: 100%;
  }
  #home #carouselHome .carousel-item .carousel-caption a {
    text-align: center;
  }
}
#home #carouselHome .carousel-item .carousel-caption h2 {
  font-family: "Encode Sans";
  font-size: 30.15px;
  color: #636362;
  font-weight: 800;
  margin-bottom: 13px;
}
#home #carouselHome .carousel-item .carousel-caption p {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #939292;
  margin-bottom: 21px;
  line-height: 20px;
}
#home #carouselHome .carousel-item .carousel-caption .btn-banner {
  display: inline-block;
  text-decoration: none;
  font-weight: 800;
  font-size: 12px;
  color: #555555;
  background-color: #D0D1D3;
  padding: 12px 24px;
  border-radius: 20px;
  transition: 0.3s ease-in;
}
#home #carouselHome .carousel-item .carousel-caption .btn-banner:hover {
  transition: 0.3s ease-in;
  background-color: #F4B344;
  color: #ffffff;
}
#home #carouselHome .carousel-indicators [data-bs-target] {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  opacity: 1;
  background-color: #F4B344;
}
#home #carouselHome .carousel-indicators {
  bottom: -43px;
  gap: 5px;
}
#home #carouselHome .carousel-indicators .active {
  background-color: #555555;
}
#home .alugar-comprar {
  margin-top: 87px;
  margin-bottom: 60px;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}
#home .alugar-comprar h3 {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #EAAF0E;
  margin-bottom: 0;
  position: relative;
}
#home .alugar-comprar h3::after {
  content: "";
  position: absolute;
  left: -60px;
  top: 8px;
  width: 52px;
  height: 2.5px;
  background-color: #EAAF0E;
}
#home .alugar-comprar h2 {
  font-family: "Encode Sans";
  font-size: 30.14px;
  color: #555555;
  font-weight: 800;
}
#home .alugar-comprar .filtros {
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 17px;
}
#home .alugar-comprar .filtros p {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
  min-width: 75px;
}
#home .alugar-comprar ul.filter.clearfix {
  display: flex;
  gap: 5px;
}
@media (max-width: 991px) {
  #home .alugar-comprar ul.filter.clearfix {
    max-width: 100%;
    overflow: auto;
  }
}
#home .alugar-comprar ul.filter.clearfix li {
  font-family: "Encode Sans";
  font-size: 12.82px;
  color: #555555;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 800;
  padding: 8px 18px;
  border-radius: 30px;
  background-color: #D0D1D3;
  cursor: pointer;
  transition: 0.3s ease-in;
}
#home .alugar-comprar ul.filter.clearfix li svg {
  width: 25px;
  height: 25px;
}
#home .alugar-comprar ul.filter.clearfix li:hover, #home .alugar-comprar ul.filter.clearfix li.active {
  background-color: #555555;
  color: #F4B344;
  transition: 0.3s ease-in;
}
#home .alugar-comprar ul.filter.clearfix li:hover svg path, #home .alugar-comprar ul.filter.clearfix li.active svg path {
  fill: #F4B344;
}
#home .alugar-comprar .slider a {
  display: inline-block;
  text-decoration: none;
  width: 100%;
}
#home .alugar-comprar .slider .itens {
  max-width: 310px;
  height: auto;
}
#home .alugar-comprar .card-movel {
  margin: 0 5px;
}
#home .alugar-comprar .card-movel .img-card {
  width: 100%;
  max-width: 300px;
  height: 340px;
  border-radius: 30px;
  position: relative;
}
@media (max-width: 991px) {
  #home .alugar-comprar .card-movel .img-card {
    max-width: 240px;
    height: 240px;
  }
}
#home .alugar-comprar .card-movel .img-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
#home .alugar-comprar .card-movel .img-card .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: rgba(26, 26, 26, 0.6509803922);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in;
  opacity: 0;
}
#home .alugar-comprar .card-movel .img-card .layer img {
  width: 57px;
  height: 57px;
  border-radius: 0;
}
#home .alugar-comprar .card-movel:hover {
  transition: 0.3s ease-in;
}
#home .alugar-comprar .card-movel:hover .layer {
  transition: 0.3s ease-in;
  opacity: 1;
}
#home .alugar-comprar .card-movel h3 {
  font-family: "Encode Sans";
  font-size: 20px;
  color: #555555;
  font-weight: 800;
  margin: 10px 0;
}
#home .alugar-comprar .card-movel h3::after {
  display: none;
}
#home .alugar-comprar .card-movel .tag {
  font-family: "Encode Sans";
  font-size: 16px;
  color: #555555;
  font-weight: 800;
  display: inline-block;
  padding: 2px 15px;
  border-radius: 30px;
  color: #ffffff;
}
#home .alugar-comprar .card-movel .preco {
  font-family: "Encode Sans";
  font-size: 16px;
  color: #555555;
  margin: 6px 8px;
}
#home .buscar {
  padding: 69px 0 217px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #F3BD12;
}
#home .buscar h3 {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #ffffff;
  position: relative;
}
@media (max-width: 991px) {
  #home .buscar h3 {
    text-align: center;
    color: #000000;
  }
}
#home .buscar h3::after {
  content: "";
  position: absolute;
  left: -60px;
  top: 8px;
  width: 52px;
  height: 2.5px;
  background-color: #ffffff;
}
#home .buscar h2 {
  font-family: "Encode Sans";
  font-weight: 800;
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 6px;
}
@media (max-width: 991px) {
  #home .buscar h2 {
    text-align: center;
    color: #000000;
  }
}
#home .buscar p {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #ffffff;
}
@media (max-width: 991px) {
  #home .buscar p {
    text-align: center;
    color: #000000;
  }
}
#home .buscar form {
  position: relative;
  max-width: 719px;
  margin: 0 auto;
}
#home .buscar form input {
  width: 100%;
  max-width: 719px;
  padding: 17px 15px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  border-radius: 30px;
  border: none;
}
#home .buscar form .select2-container--default .select2-selection--single,
#home .buscar form input,
#home .buscar form input::placeholder {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #555555;
  border: none;
}
#home .buscar form .select2-selection__arrow {
  display: none;
}
#home .buscar form button {
  font-family: "Encode Sans";
  font-size: 13px;
  font-weight: 800;
  color: #F4B344;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 33px;
  gap: 10px;
  padding: 8px 28px;
  border-radius: 30px;
  background-color: #555555;
  transition: 0.3s ease-in;
}
@media (max-width: 991px) {
  #home .buscar form button {
    font-size: 13px;
    padding: 8px;
  }
}
#home .buscar form button img {
  width: 29px;
  height: 29px;
}
#home .buscar form button:hover {
  transition: 0.3s ease-in;
  background-color: #353434;
}
#home .buscar form .select2-container {
  width: 100% !important;
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  padding: 13px 15px;
  background: #fff;
  border-radius: 30px;
}
@media (max-width: 991px) {
  #home .buscar form .select2-container--default .select2-selection--single .select2-selection__rendered {
    max-width: 60% !important;
  }
}
#home .destaques {
  padding: 77px 0;
  overflow: hidden;
}
#home .destaques #carouselDestaque {
  position: relative;
}
#home .destaques #carouselDestaque::after {
  content: "";
  position: absolute;
  top: -34px;
  right: -23px;
  width: 72px;
  height: 248px;
  background: url("../img/home/elemento1.png") center center no-repeat;
  background-size: cover;
  z-index: -1;
}
#home .destaques #carouselDestaque::before {
  content: "";
  position: absolute;
  bottom: -35px;
  right: -129px;
  width: 386px;
  height: 119px;
  background: url("../img/home/elemento2.png") center center no-repeat;
  background-size: cover;
  z-index: -1;
}
@media (max-width: 991px) {
  #home .destaques #carouselDestaque::before {
    bottom: 190px;
  }
}
#home .destaques #carouselDestaque .carousel-caption {
  position: relative;
  text-align: left;
  color: #555555;
}
#home .destaques #carouselDestaque .carousel-caption h3 {
  font-family: "Roboto Flex";
  font-size: 14px;
  position: relative;
  margin-top: 22px;
}
#home .destaques #carouselDestaque .carousel-caption h3::after {
  content: "";
  position: absolute;
  left: -60px;
  top: 8px;
  width: 52px;
  height: 2.5px;
  background-color: #555555;
}
#home .destaques #carouselDestaque .carousel-caption h2 {
  font-family: "Encode Sans";
  font-size: 30px;
  font-weight: 800;
}
#home .destaques #carouselDestaque .carousel-caption p {
  font-family: "Roboto Flex";
  font-size: 14px;
  max-width: 400px;
}
#home .destaques #carouselDestaque .carousel-caption a {
  display: inline-block;
  text-decoration: none;
  font-family: "Encode Sans";
  font-size: 13px;
  color: #F4B344;
  font-weight: 800;
  padding: 12px 35px;
  border-radius: 30px;
  background-color: #555555;
  transition: 0.3s ease-in;
  margin-top: 8px;
}
#home .destaques #carouselDestaque .carousel-caption a:hover {
  transition: 0.3s ease-in;
  background-color: #353434;
}
#home .destaques #carouselDestaque .carousel-indicators [data-bs-target] {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  opacity: 1;
  background-color: #F4B344;
}
#home .destaques #carouselDestaque .carousel-indicators {
  bottom: 58px;
  gap: 5px;
  right: inherit;
  left: -68px;
}
@media (max-width: 991px) {
  #home .destaques #carouselDestaque .carousel-indicators {
    bottom: -44px;
    gap: 5px;
    right: 0;
    left: 0;
  }
}
#home .destaques #carouselDestaque .carousel-indicators .active {
  background-color: #555555;
}
#home .destaques #carouselDestaque .img-destaque {
  width: 100%;
  height: 352px;
  max-width: 508px;
  border-radius: 30px;
  float: right;
}
#home .destaques #carouselDestaque .img-destaque img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
}
@media (max-width: 991px) {
  #home .destaques #carouselDestaque .carousel-item .row {
    flex-direction: column-reverse;
  }
}
#home .depoimentos {
  padding: 44px 0 55px 0;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}
#home .depoimentos h3 {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #F4B344;
  position: relative;
  text-align: center;
  padding: 8px 0;
  margin-bottom: -6px;
}
#home .depoimentos h3::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 2px;
  width: 52px;
  background-color: #F4B344;
}
#home .depoimentos h2 {
  font-family: "Encode Sans";
  font-size: 30px;
  font-weight: 800;
  color: #555555;
  text-align: center;
  margin-bottom: 30px;
}
#home .depoimentos .lista-depoimentos .depoimento {
  margin: 0 30px;
  position: relative;
  margin-bottom: 190px;
  max-width: 700px;
}
@media (max-width: 991px) {
  #home .depoimentos .lista-depoimentos .depoimento {
    margin-bottom: 0px;
  }
}
#home .depoimentos .lista-depoimentos .depoimento .img-depoimento {
  width: 100%;
  max-width: 682px;
  height: 382px;
  border-radius: 30px;
  -webkit-box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);
}
#home .depoimentos .lista-depoimentos .depoimento .img-depoimento img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
#home .depoimentos .lista-depoimentos .depoimento .texto-depoimento {
  width: 90%;
  height: 326px;
  margin: 0 auto;
  padding: 77px 40px;
  position: absolute;
  bottom: -142px;
  left: 0;
  right: 0;
  border-radius: 30px;
  -webkit-box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
  background-color: #DFDFDF;
}
@media (max-width: 991px) {
  #home .depoimentos .lista-depoimentos .depoimento .texto-depoimento {
    position: relative;
    bottom: 75px;
    height: auto;
    padding: 40px 20;
  }
}
#home .depoimentos .lista-depoimentos .depoimento .texto-depoimento p {
  font-family: "Roboto Flex";
  font-size: 20px;
  color: #000000;
  margin-bottom: 30px;
}
#home .depoimentos .lista-depoimentos .depoimento .texto-depoimento .nome {
  font-family: "Roboto";
  font-style: italic;
  font-size: 15px;
  color: #000000;
  font-weight: 700;
}
#home .depoimentos .lista-depoimentos .slick-slide {
  height: auto;
}
#home .depoimentos .lista-depoimentos .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding-left: 0;
}
#home .depoimentos .lista-depoimentos .slick-dots li {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: #F4B344;
}
#home .depoimentos .lista-depoimentos .slick-dots li.slick-active {
  background-color: #555555;
}
#home .depoimentos .lista-depoimentos .slick-dots li button {
  display: none;
}

#imoveis {
  background: url("../img/home/background-home.png") top center no-repeat;
  background-size: contain;
}
#imoveis #carouselHome {
  position: relative;
}
#imoveis #carouselHome .carousel-item {
  width: 100%;
  height: 347px;
}
@media (max-width: 991px) {
  #imoveis #carouselHome .carousel-item {
    height: auto;
  }
}
#imoveis #carouselHome .carousel-item img {
  position: absolute;
  width: 100%;
  top: 0;
  right: -33.3%;
  z-index: 1;
  border-radius: 0px 0px 0px 68px;
}
@media (max-width: 991px) {
  #imoveis #carouselHome .carousel-item img {
    position: relative;
    height: 147px;
  }
}
#imoveis #carouselHome .carousel-item .carousel-caption {
  right: inherit;
  left: 0;
  top: 135px;
  text-align: left;
  z-index: 3;
  max-width: 310px;
}
@media (max-width: 991px) {
  #imoveis #carouselHome .carousel-item .carousel-caption {
    position: relative;
    top: 0;
    max-width: 100%;
    text-align: center;
  }
}
#imoveis #carouselHome .carousel-item .carousel-caption h2 {
  font-family: "Encode Sans";
  font-size: 30.15px;
  color: #636362;
  font-weight: 800;
  margin-bottom: 13px;
}
#imoveis #carouselHome .carousel-item .carousel-caption p {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #555555;
  margin-bottom: 21px;
  line-height: 20px;
}
#imoveis #carouselHome .carousel-item .carousel-caption .btn-banner {
  display: inline-block;
  text-decoration: none;
  font-weight: 800;
  font-size: 12px;
  color: #555555;
  background-color: #D0D1D3;
  padding: 12px 24px;
  border-radius: 20px;
  transition: 0.3s ease-in;
}
#imoveis #carouselHome .carousel-item .carousel-caption .btn-banner:hover {
  transition: 0.3s ease-in;
  background-color: #F4B344;
  color: #ffffff;
}
#imoveis #carouselHome .carousel-indicators [data-bs-target] {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  opacity: 1;
  background-color: #F4B344;
}
#imoveis #carouselHome .carousel-indicators {
  bottom: -43px;
  gap: 5px;
}
#imoveis #carouselHome .carousel-indicators .active {
  background-color: #555555;
}
#imoveis .alugar-comprar {
  margin-top: 87px;
  margin-bottom: 60px;
}
@media (max-width: 991px) {
  #imoveis .alugar-comprar {
    margin-top: 0px;
    padding-top: 37px;
  }
}
#imoveis .alugar-comprar h3 {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #EAAF0E;
  margin-bottom: 0;
  position: relative;
}
#imoveis .alugar-comprar h3::after {
  content: "";
  position: absolute;
  left: -60px;
  top: 8px;
  width: 52px;
  height: 2.5px;
  background-color: #EAAF0E;
}
#imoveis .alugar-comprar h2 {
  font-family: "Encode Sans";
  font-size: 30.14px;
  color: #555555;
  font-weight: 800;
}
#imoveis .alugar-comprar .filtros {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
@media (max-width: 991px) {
  #imoveis .alugar-comprar .filtros {
    display: block;
  }
}
#imoveis .alugar-comprar .filtros p {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
}
#imoveis .alugar-comprar .filtros select,
#imoveis .alugar-comprar .filtros option {
  font-family: "Encode Sans";
  font-size: 13.82px;
  color: #555555;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 800;
  padding: 11px 50px;
  border-radius: 30px;
  background-color: #D0D1D3;
  cursor: pointer;
  border: none;
}
#imoveis .alugar-comprar .filtros select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #D0D1D3 url("../img/seta-select.png") 93.5% 50% no-repeat;
  background-size: 21px;
}
@media (max-width: 991px) {
  #imoveis .alugar-comprar .filtros select {
    text-align: center;
  }
}
#imoveis .alugar-comprar .filtros .filter2 {
  display: flex;
  gap: 35px;
  padding-left: 2rem;
}
@media (max-width: 991px) {
  #imoveis .alugar-comprar .filtros .filter2 {
    display: inline-grid;
    gap: 15px;
    width: 100%;
    padding-right: 2rem;
  }
}
#imoveis .alugar-comprar .filtros .filter2 a {
  font-weight: 800;
  font-size: 13px;
  color: #F4B344;
  background-color: #555555;
  padding: 11px 41px;
  border-radius: 30px;
  position: relative;
  transition: 0.3s ease-in;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 991px) {
  #imoveis .alugar-comprar .filtros .filter2 a {
    text-align: center;
  }
}
#imoveis .alugar-comprar .filtros .filter2 a:hover {
  transition: 0.3s ease-in;
  background-color: #F4B344;
  color: #555555;
}
#imoveis .alugar-comprar .filtros .filter2 a::after {
  content: "";
  position: absolute;
  top: -9px;
  left: -17px;
  width: 2px;
  height: 63px;
  background-color: #555555;
}
@media (max-width: 991px) {
  #imoveis .alugar-comprar .filtros .filter2 a::after {
    display: none;
  }
}
#imoveis .alugar-comprar ul.filter.clearfix {
  display: flex;
  gap: 5px;
}
@media (max-width: 991px) {
  #imoveis .alugar-comprar ul.filter.clearfix {
    max-width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-left: 0;
    gap: 15px 5px;
    align-items: center;
    justify-content: center;
  }
}
#imoveis .alugar-comprar ul.filter.clearfix li {
  font-family: "Encode Sans";
  font-size: 12.82px;
  color: #555555;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 800;
  padding: 8px 18px;
  border-radius: 30px;
  background-color: #D0D1D3;
  cursor: pointer;
  transition: 0.3s ease-in;
}
#imoveis .alugar-comprar ul.filter.clearfix li svg {
  width: 25px;
  height: 25px;
}
#imoveis .alugar-comprar ul.filter.clearfix li:hover, #imoveis .alugar-comprar ul.filter.clearfix li.active {
  background-color: #555555;
  color: #F4B344;
  transition: 0.3s ease-in;
}
#imoveis .alugar-comprar ul.filter.clearfix li:hover svg path, #imoveis .alugar-comprar ul.filter.clearfix li.active svg path {
  fill: #F4B344;
}
#imoveis .alugar-comprar ul.filter.clearfix li label {
  display: flex;
  align-items: center;
  gap: 10px;
}
#imoveis .alugar-comprar ul.filter.clearfix li label input {
  display: none;
}
#imoveis .lista-imoveis {
  padding-bottom: 80px;
}
#imoveis .lista-imoveis a {
  display: inline-block;
  text-decoration: none;
}
@media (max-width: 991px) {
  #imoveis .lista-imoveis a {
    width: 100%;
  }
}
#imoveis .lista-imoveis .card-imovel {
  width: 346px;
  max-width: 100%;
  margin-bottom: 70px;
}
@media (max-width: 991px) {
  #imoveis .lista-imoveis .card-imovel {
    width: 100%;
    margin-bottom: 30px;
  }
}
#imoveis .lista-imoveis .card-imovel .img-imovel {
  width: 100%;
  height: 246px;
  border-radius: 30px;
  margin-bottom: 25px;
  position: relative;
  transition: 0.3s ease-in;
}
#imoveis .lista-imoveis .card-imovel .img-imovel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
#imoveis .lista-imoveis .card-imovel .img-imovel .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: rgba(26, 26, 26, 0.6509803922);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in;
  opacity: 0;
}
#imoveis .lista-imoveis .card-imovel .img-imovel .layer img {
  width: 57px;
  height: 57px;
  border-radius: 0;
}
#imoveis .lista-imoveis .card-imovel .img-imovel:hover {
  transition: 0.3s ease-in;
}
#imoveis .lista-imoveis .card-imovel .img-imovel:hover .layer {
  transition: 0.3s ease-in;
  opacity: 1;
}
#imoveis .lista-imoveis .card-imovel h2 {
  font-weight: 800;
  color: #555555;
  font-size: 16px;
  margin-bottom: 0px;
}
#imoveis .lista-imoveis .card-imovel .preco {
  font-size: 20px;
  color: #000000;
}
#imoveis .lista-imoveis .card-imovel p {
  font-size: 12px;
  color: #555555;
  margin-bottom: 5px;
}
#imoveis .lista-imoveis .card-imovel .bairro {
  font-size: 13px;
  color: #555555;
}
#imoveis .lista-imoveis .card-imovel .bairro span {
  font-size: 13px;
  color: #F4B344;
}
#imoveis .lista-imoveis .card-imovel .descricao-imovel {
  max-width: 246px;
  margin-left: 20px;
  margin-bottom: 20px;
}
#imoveis .box {
  float: left;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5803921569);
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  right: 0;
  z-index: 999;
}
#imoveis .box-inner {
  width: 700px;
  float: right;
  right: 0;
  background: rgba(234, 234, 234, 0.8);
  min-height: 100vh;
  padding: 0 40px 50px 40px;
}
@media (max-width: 1100px) {
  #imoveis .box-inner {
    width: 100%;
    min-height: 100vh;
  }
}
#imoveis .box-inner .topo-menu {
  position: relative;
  margin: 50px 50px;
}
#imoveis .box-inner .topo-menu .slide-toggle {
  cursor: pointer;
}
#imoveis .box-inner .topo-menu .slide-toggle img {
  max-width: 33px;
  float: right;
}
#imoveis .box-inner .filtro-menu {
  clear: both;
}
#imoveis .box-inner .filtro-menu h3 {
  font-size: 30px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 40px;
}
#imoveis .box-inner .filtro-menu h3 strong {
  font-size: 33px;
  font-weight: 600;
}
#imoveis .box-inner .filtro-menu h4 {
  font-weight: 900;
  font-size: 21px;
  color: #555555;
  margin: 30px 0;
}
#imoveis .box-inner .filtro-menu label {
  width: 100%;
  font-size: 12px;
  color: #000000;
  margin-bottom: 5px;
}
#imoveis .box-inner .filtro-menu input {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #555555;
  padding: 10px;
  border-radius: 30px;
  border: solid 1px #555555;
}
#imoveis .box-inner .filtro-menu button {
  font-weight: 900;
  font-size: 13px;
  color: #F4B344;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 21px;
  border-radius: 30px;
  background-color: #555555;
  margin-top: 30px;
  margin-left: -20px;
}
#imoveis .box-inner .filtro-menu button img {
  max-width: 30px;
}
#imoveis .box-inner .filtro-menu .opcao-tipo {
  font-size: 14px;
  color: #000000;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 5px;
}
#imoveis .box-inner .filtro-menu .bloco-busca {
  position: relative;
}
#imoveis .box-inner .filtro-menu .bloco-busca::after {
  content: "";
  position: absolute;
  left: -20px;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #F4B344;
}

#imovel-detalhe {
  background: url("../img/home/background-home.png") top center no-repeat;
  background-size: contain;
  position: relative;
  padding-top: 170px;
}
#imovel-detalhe::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 137px;
  background-color: #636362;
  border-radius: 0 0 0 60px;
}
@media (max-width: 991px) {
  #imovel-detalhe::after {
    display: none;
  }
}
@media (max-width: 991px) {
  #imovel-detalhe {
    padding-top: 50px;
  }
}
#imovel-detalhe h1 {
  font-weight: 800;
  font-size: 27px;
  color: #000000;
  margin-bottom: 20px;
}
#imovel-detalhe p {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #000000;
}
#imovel-detalhe .valor {
  font-size: 22px;
  color: #636362;
  margin-bottom: 20px;
}
#imovel-detalhe .valor span {
  font-size: 41px;
  font-weight: 600;
}
#imovel-detalhe .btns a {
  display: inline-block;
  margin-bottom: 5px;
  max-width: 200px;
}
#imovel-detalhe .descricao-imovel {
  padding-left: 15px;
  border-left: solid 4px #F4B344;
}
@media (max-width: 991px) {
  #imovel-detalhe .descricao-imovel {
    margin-bottom: 50px;
  }
}
#imovel-detalhe h2 {
  font-size: 14px;
  color: #555555;
  margin-top: 20px;
  margin-bottom: 10px;
}
#imovel-detalhe ul li {
  font-size: 14px;
  color: #000000;
  list-style: disc;
  margin-bottom: 5px;
}
#imovel-detalhe .img-destaque {
  width: 100%;
  height: 486px;
  margin-bottom: 15px;
  cursor: pointer;
}
#imovel-detalhe .img-destaque img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 60px 0 0 0;
}
#imovel-detalhe .img-diversos {
  width: 100%;
  height: 271px;
  margin-bottom: 15px;
  cursor: pointer;
}
#imovel-detalhe .img-diversos img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
#imovel-detalhe .interesse {
  background-color: #222222;
  padding: 50px 0;
  margin-top: 100px;
}
#imovel-detalhe .interesse h2 {
  font-family: "Encode Sans";
  font-size: 24px;
  color: #ffffff;
  position: relative;
  font-weight: 800;
  margin-left: 30px;
}
#imovel-detalhe .interesse h2::after {
  content: "";
  position: absolute;
  top: -12px;
  left: 0;
  height: 3px;
  width: 76px;
  background-color: #ffffff;
}
#imovel-detalhe .interesse p {
  font-family: "Encode Sans";
  font-size: 22px;
  color: #ffffff;
  margin-left: 30px;
  line-height: 27px;
}
#imovel-detalhe .interesse form {
  margin-top: 30px;
}
#imovel-detalhe .interesse form .form-control,
#imovel-detalhe .interesse form label,
#imovel-detalhe .interesse form textarea {
  width: 100%;
}
#imovel-detalhe .interesse form label {
  font-family: "Encode Sans";
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  margin-left: 32px;
  margin-bottom: 6px;
}
@media (max-width: 991px) {
  #imovel-detalhe .interesse form label {
    max-width: 100%;
    margin-left: 0;
  }
}
#imovel-detalhe .interesse form .form-check label {
  margin-left: 0 !important;
}
#imovel-detalhe .interesse form .form-control,
#imovel-detalhe .interesse form textarea,
#imovel-detalhe .interesse form .form-control::placeholder {
  font-family: "Montserrat";
  font-size: 13px;
  color: #777777;
}
#imovel-detalhe .interesse form .form-control,
#imovel-detalhe .interesse form textarea {
  padding: 8px 32px;
  border: none;
  border-radius: 30px;
  margin-bottom: 31px;
  background-color: #FFF3EA;
}
#imovel-detalhe .interesse form textarea {
  min-height: 160px;
}
#imovel-detalhe .interesse form button {
  font-family: "Encode Sans";
  font-size: 12px;
  font-weight: 800;
  color: #555555;
  background-color: #F4B344;
  border-radius: 30px;
  padding: 12px 47px;
  float: right;
  transition: 0.3s ease-in;
}
#imovel-detalhe .interesse form button:hover {
  transition: 0.3s ease-in;
  color: #F4B344;
  background-color: #353434;
}
#imovel-detalhe .compartilhar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0;
  border-bottom: solid 1px #6d5644;
  border-top: solid 1px #6d5644;
  margin-bottom: 30px;
  transition: 0.3s ease-in;
  font-size: 14px;
  color: #555555;
}
#imovel-detalhe .compartilhar a {
  text-decoration: none;
  margin: 0 10px;
}
#imovel-detalhe .compartilhar a:hover {
  transition: 0.3s ease-in;
  color: #f7ae63;
}
#imovel-detalhe .container-imagens {
  padding-left: 100px;
  position: relative;
}
@media (max-width: 991px) {
  #imovel-detalhe .container-imagens {
    padding-left: 0;
  }
}
#imovel-detalhe .container-imagens::after {
  content: "";
  position: absolute;
  top: -179px;
  right: 0;
  left: 100px;
  width: 100%;
  height: 146px;
  background-color: #636362;
  border-radius: 0 0 0 60px;
}
@media (max-width: 991px) {
  #imovel-detalhe .container-imagens::after {
    display: none;
  }
}

#lancamentos {
  background: url("../img/home/background-home.png") top center no-repeat;
  background-size: contain;
  position: relative;
}
#lancamentos::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 137px;
  background-color: #636362;
  border-radius: 0 0 0 60px;
}
@media (max-width: 991px) {
  #lancamentos {
    padding-top: 50px;
  }
}
#lancamentos .container-imagens {
  padding-left: 100px;
  position: relative;
  float: right;
  height: 176px;
}
@media (max-width: 991px) {
  #lancamentos .container-imagens {
    padding-left: 0;
  }
}
#lancamentos .container-imagens::after {
  content: "";
  position: absolute;
  top: 0;
  right: -2px;
  width: 100%;
  height: 137px;
  background-color: #636362;
  border-radius: 0 0 0 60px;
}
@media (max-width: 991px) {
  #lancamentos .container-imagens::after {
    display: none;
  }
}
#lancamentos .destaques {
  padding: 77px 0;
  overflow: hidden;
  clear: both;
}
@media (max-width: 991px) {
  #lancamentos .destaques {
    padding: 0;
  }
}
#lancamentos .destaques #carouselDestaque {
  position: relative;
}
#lancamentos .destaques #carouselDestaque .carousel-caption {
  position: relative;
  text-align: left;
  color: #555555;
  left: 0;
}
@media (max-width: 991px) {
  #lancamentos .destaques #carouselDestaque .carousel-caption {
    left: 0;
  }
}
#lancamentos .destaques #carouselDestaque .carousel-caption h3 {
  font-family: "Roboto Flex";
  font-size: 14px;
  position: relative;
  margin-top: 22px;
}
#lancamentos .destaques #carouselDestaque .carousel-caption h3::after {
  content: "";
  position: absolute;
  left: -60px;
  top: 8px;
  width: 52px;
  height: 2.5px;
  background-color: #555555;
}
#lancamentos .destaques #carouselDestaque .carousel-caption h2 {
  font-family: "Encode Sans";
  font-size: 30px;
  font-weight: 800;
}
#lancamentos .destaques #carouselDestaque .carousel-caption p {
  font-family: "Roboto Flex";
  font-size: 14px;
  max-width: 400px;
}
#lancamentos .destaques #carouselDestaque .carousel-caption a {
  display: inline-block;
  text-decoration: none;
  font-family: "Encode Sans";
  font-size: 13px;
  color: #F4B344;
  font-weight: 800;
  padding: 12px 35px;
  border-radius: 30px;
  background-color: #555555;
  transition: 0.3s ease-in;
  margin-top: 8px;
}
#lancamentos .destaques #carouselDestaque .carousel-caption a:hover {
  transition: 0.3s ease-in;
  background-color: #353434;
}
#lancamentos .destaques #carouselDestaque .img-destaque {
  width: 100%;
  height: 352px;
  max-width: 100%;
  border-radius: 30px;
  float: right;
  margin-bottom: 50px;
}
#lancamentos .destaques #carouselDestaque .img-destaque img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
}
@media (max-width: 991px) {
  #lancamentos .destaques #carouselDestaque .carousel-item .row {
    flex-direction: column-reverse;
  }
}

#blog-listagem {
  background: url("../img/home/background-home.png") top center no-repeat;
}
#blog-listagem #carouselHome {
  position: relative;
}
#blog-listagem #carouselHome .carousel-item {
  width: 100%;
  height: 347px;
}
@media (max-width: 991px) {
  #blog-listagem #carouselHome .carousel-item {
    height: auto;
  }
}
#blog-listagem #carouselHome .carousel-item img {
  position: absolute;
  width: 100%;
  top: 0;
  right: -33.3%;
  z-index: 1;
  border-radius: 0px 0px 0px 68px;
}
@media (max-width: 991px) {
  #blog-listagem #carouselHome .carousel-item img {
    position: relative;
    height: 147px;
  }
}
#blog-listagem #carouselHome .carousel-item .carousel-caption {
  right: inherit;
  left: 0;
  top: 135px;
  text-align: left;
  z-index: 3;
  max-width: 310px;
}
@media (max-width: 991px) {
  #blog-listagem #carouselHome .carousel-item .carousel-caption {
    position: relative;
    top: 0;
    max-width: 100%;
    text-align: center;
  }
}
#blog-listagem #carouselHome .carousel-item .carousel-caption h2 {
  font-family: "Encode Sans";
  font-size: 30.15px;
  color: #636362;
  font-weight: 800;
  margin-bottom: 13px;
}
#blog-listagem #carouselHome .carousel-item .carousel-caption p {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #555555;
  margin-bottom: 21px;
  line-height: 20px;
}
#blog-listagem #carouselHome .carousel-item .carousel-caption .btn-banner {
  display: inline-block;
  text-decoration: none;
  font-weight: 800;
  font-size: 12px;
  color: #555555;
  background-color: #D0D1D3;
  padding: 12px 24px;
  border-radius: 20px;
  transition: 0.3s ease-in;
}
#blog-listagem #carouselHome .carousel-item .carousel-caption .btn-banner:hover {
  transition: 0.3s ease-in;
  background-color: #F4B344;
  color: #ffffff;
}
#blog-listagem #carouselHome .carousel-indicators [data-bs-target] {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  opacity: 1;
  background-color: #F4B344;
}
#blog-listagem #carouselHome .carousel-indicators {
  bottom: -43px;
  gap: 5px;
}
#blog-listagem #carouselHome .carousel-indicators .active {
  background-color: #555555;
}
#blog-listagem .alugar-comprar {
  margin-top: 87px;
  margin-bottom: 60px;
}
@media (max-width: 991px) {
  #blog-listagem .alugar-comprar {
    margin-top: 37px;
  }
}
#blog-listagem .alugar-comprar h3 {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #EAAF0E;
  margin-bottom: 0;
  position: relative;
}
#blog-listagem .alugar-comprar h3::after {
  content: "";
  position: absolute;
  left: -60px;
  top: 8px;
  width: 52px;
  height: 2.5px;
  background-color: #EAAF0E;
}
#blog-listagem .alugar-comprar h2 {
  font-family: "Encode Sans";
  font-size: 30.14px;
  color: #555555;
  font-weight: 800;
}
#blog-listagem .alugar-comprar .filtros {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
@media (max-width: 991px) {
  #blog-listagem .alugar-comprar .filtros {
    display: block;
  }
}
#blog-listagem .alugar-comprar .filtros p {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
}
#blog-listagem .alugar-comprar .filtros select,
#blog-listagem .alugar-comprar .filtros option {
  font-family: "Encode Sans";
  font-size: 13.82px;
  color: #555555;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 800;
  padding: 11px 50px;
  border-radius: 30px;
  background-color: #D0D1D3;
  cursor: pointer;
  border: none;
}
#blog-listagem .alugar-comprar .filtros select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #D0D1D3 url("../img/seta-select.png") 93.5% 50% no-repeat;
  background-size: 21px;
}
@media (max-width: 991px) {
  #blog-listagem .alugar-comprar .filtros select {
    text-align: center;
  }
}
#blog-listagem .alugar-comprar .filtros .filter2 {
  display: flex;
  gap: 35px;
  padding-left: 2rem;
}
@media (max-width: 991px) {
  #blog-listagem .alugar-comprar .filtros .filter2 {
    display: inline-grid;
    gap: 15px;
    width: 100%;
    padding-right: 2rem;
  }
}
#blog-listagem .alugar-comprar .filtros .filter2 a {
  font-weight: 800;
  font-size: 13px;
  color: #F4B344;
  background-color: #555555;
  padding: 11px 41px;
  border-radius: 30px;
  position: relative;
  transition: 0.3s ease-in;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 991px) {
  #blog-listagem .alugar-comprar .filtros .filter2 a {
    text-align: center;
  }
}
#blog-listagem .alugar-comprar .filtros .filter2 a:hover {
  transition: 0.3s ease-in;
  background-color: #F4B344;
  color: #555555;
}
#blog-listagem .alugar-comprar .filtros .filter2 a::after {
  content: "";
  position: absolute;
  top: -9px;
  left: -17px;
  width: 2px;
  height: 63px;
  background-color: #555555;
}
@media (max-width: 991px) {
  #blog-listagem .alugar-comprar .filtros .filter2 a::after {
    display: none;
  }
}
#blog-listagem .alugar-comprar ul.filter.clearfix {
  display: flex;
  gap: 5px;
}
@media (max-width: 991px) {
  #blog-listagem .alugar-comprar ul.filter.clearfix {
    max-width: 100%;
    overflow: auto;
  }
}
#blog-listagem .alugar-comprar ul.filter.clearfix li {
  font-family: "Encode Sans";
  font-size: 13.82px;
  color: #555555;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 800;
  padding: 11px 27px;
  border-radius: 30px;
  background-color: #D0D1D3;
  cursor: pointer;
  transition: 0.3s ease-in;
}
#blog-listagem .alugar-comprar ul.filter.clearfix li svg {
  width: 30px;
  height: 30px;
}
#blog-listagem .alugar-comprar ul.filter.clearfix li:hover, #blog-listagem .alugar-comprar ul.filter.clearfix li.active {
  background-color: #555555;
  color: #F4B344;
  transition: 0.3s ease-in;
}
#blog-listagem .alugar-comprar ul.filter.clearfix li:hover svg path, #blog-listagem .alugar-comprar ul.filter.clearfix li.active svg path {
  fill: #F4B344;
}
#blog-listagem .alugar-comprar ul.filter.clearfix li label {
  display: flex;
  align-items: center;
  gap: 10px;
}
#blog-listagem .alugar-comprar ul.filter.clearfix li label input {
  display: none;
}
#blog-listagem .lista-imoveis {
  padding-bottom: 80px;
}
#blog-listagem .lista-imoveis a {
  display: inline-block;
  text-decoration: none;
}
@media (max-width: 991px) {
  #blog-listagem .lista-imoveis a {
    width: 100%;
  }
}
#blog-listagem .lista-imoveis .card-imovel {
  width: 100%;
  max-width: 100%;
  margin-bottom: 70px;
}
@media (max-width: 991px) {
  #blog-listagem .lista-imoveis .card-imovel {
    max-width: 100%;
    margin-bottom: 30px;
  }
}
#blog-listagem .lista-imoveis .card-imovel .img-imovel {
  width: 100%;
  height: 246px;
  border-radius: 30px;
  margin-bottom: 25px;
}
#blog-listagem .lista-imoveis .card-imovel .img-imovel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
#blog-listagem .lista-imoveis .card-imovel h2 {
  font-weight: 800;
  color: #555555;
  font-size: 22px;
  margin-bottom: 0px;
}
#blog-listagem .lista-imoveis .card-imovel .preco {
  font-size: 20px;
  color: #000000;
}
#blog-listagem .lista-imoveis .card-imovel p {
  font-size: 12px;
  color: #555555;
  margin-bottom: 5px;
}
#blog-listagem .lista-imoveis .card-imovel .bairro {
  font-size: 13px;
  color: #555555;
}
#blog-listagem .lista-imoveis .card-imovel .bairro span {
  font-size: 13px;
  color: #F4B344;
}
#blog-listagem .lista-imoveis .card-imovel .descricao-imovel {
  max-width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
#blog-listagem .box {
  float: left;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5803921569);
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  right: 0;
  z-index: 999;
}
#blog-listagem .box-inner {
  width: 700px;
  float: right;
  right: 0;
  background: rgba(234, 234, 234, 0.8);
  min-height: 100vh;
  padding: 0 40px 50px 40px;
}
@media (max-width: 1100px) {
  #blog-listagem .box-inner {
    width: 100%;
    min-height: 100vh;
  }
}
#blog-listagem .box-inner .topo-menu {
  position: relative;
  margin: 50px 50px;
}
#blog-listagem .box-inner .topo-menu .slide-toggle {
  cursor: pointer;
}
#blog-listagem .box-inner .topo-menu .slide-toggle img {
  max-width: 33px;
  float: right;
}
#blog-listagem .box-inner .filtro-menu {
  clear: both;
}
#blog-listagem .box-inner .filtro-menu h3 {
  font-size: 30px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 40px;
}
#blog-listagem .box-inner .filtro-menu h3 strong {
  font-size: 33px;
  font-weight: 600;
}
#blog-listagem .box-inner .filtro-menu h4 {
  font-weight: 900;
  font-size: 21px;
  color: #555555;
  margin: 30px 0;
}
#blog-listagem .box-inner .filtro-menu label {
  width: 100%;
  font-size: 10px;
  color: #000000;
  margin-bottom: 5px;
}
#blog-listagem .box-inner .filtro-menu input {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #555555;
  padding: 10px;
  border-radius: 30px;
  border: solid 1px #555555;
}
#blog-listagem .box-inner .filtro-menu button {
  font-weight: 900;
  font-size: 13px;
  color: #F4B344;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 21px;
  border-radius: 30px;
  background-color: #555555;
  margin-top: 30px;
  margin-left: -20px;
}
#blog-listagem .box-inner .filtro-menu button img {
  max-width: 30px;
}
#blog-listagem .box-inner .filtro-menu .opcao-tipo {
  font-size: 14px;
  color: #000000;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 5px;
}
#blog-listagem .box-inner .filtro-menu .bloco-busca {
  position: relative;
}
#blog-listagem .box-inner .filtro-menu .bloco-busca::after {
  content: "";
  position: absolute;
  left: -20px;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #F4B344;
}

#blog-detalhe {
  background: url("../img/home/background-home.png") top center no-repeat;
  background-size: contain;
  position: relative;
  padding-top: 170px;
}
@media (max-width: 991px) {
  #blog-detalhe {
    padding-top: 50px;
  }
}
#blog-detalhe::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 67%;
  height: 146px;
  background-color: #636362;
  border-radius: 0 0 0 60px;
}
@media (max-width: 991px) {
  #blog-detalhe::after {
    display: none;
  }
}
#blog-detalhe h1 {
  font-weight: 800;
  font-size: 27px;
  color: #000000;
  margin-bottom: 20px;
}
#blog-detalhe p {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #000000;
}
#blog-detalhe .valor {
  font-size: 22px;
  color: #636362;
  margin-bottom: 20px;
}
#blog-detalhe .valor span {
  font-size: 41px;
  font-weight: 600;
}
#blog-detalhe .btns a {
  display: inline-block;
  margin-bottom: 5px;
}
#blog-detalhe .descricao-imovel {
  padding-left: 15px;
  border-left: solid 4px #F4B344;
}
@media (max-width: 991px) {
  #blog-detalhe .descricao-imovel {
    margin-bottom: 50px;
  }
}
#blog-detalhe h2 {
  font-size: 16px;
  color: #555555;
  margin-top: 20px;
  margin-bottom: 10px;
}
#blog-detalhe ul li {
  font-size: 14px;
  color: #000000;
  list-style: disc;
  margin-bottom: 5px;
}
#blog-detalhe .img-destaque {
  width: 100%;
  height: 486px;
  margin-bottom: 15px;
  cursor: pointer;
}
#blog-detalhe .img-destaque img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 60px 0 0 0;
}
#blog-detalhe .img-diversos {
  width: 100%;
  height: 271px;
  margin-bottom: 15px;
  cursor: pointer;
}
#blog-detalhe .img-diversos img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
#blog-detalhe .interesse {
  background: url("../img/imovel-detalhe/bg-interesse.png") top center no-repeat;
  background-size: cover;
  padding: 50px 0;
  margin-top: 100px;
}
#blog-detalhe .interesse h2 {
  font-family: "Encode Sans";
  font-size: 26px;
  color: #ffffff;
  position: relative;
  font-weight: 800;
  margin-left: 30px;
}
#blog-detalhe .interesse h2::after {
  content: "";
  position: absolute;
  top: -12px;
  left: 0;
  height: 3px;
  width: 76px;
  background-color: #ffffff;
}
#blog-detalhe .interesse p {
  font-family: "Encode Sans";
  font-size: 22px;
  color: #ffffff;
  margin-left: 30px;
  line-height: 27px;
}
#blog-detalhe .interesse form {
  margin-top: 30px;
}
#blog-detalhe .interesse form input,
#blog-detalhe .interesse form label,
#blog-detalhe .interesse form textarea {
  width: 100%;
}
#blog-detalhe .interesse form label {
  font-family: "Encode Sans";
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  margin-left: 32px;
  margin-bottom: 6px;
}
@media (max-width: 991px) {
  #blog-detalhe .interesse form label {
    max-width: 100%;
    margin-left: 0;
  }
}
#blog-detalhe .interesse form input,
#blog-detalhe .interesse form textarea,
#blog-detalhe .interesse form input::placeholder {
  font-family: "Montserrat";
  font-size: 11px;
  color: #777777;
}
#blog-detalhe .interesse form input,
#blog-detalhe .interesse form textarea {
  padding: 8px 32px;
  border: none;
  border-radius: 30px;
  margin-bottom: 31px;
  background-color: #FFF3EA;
}
#blog-detalhe .interesse form textarea {
  min-height: 160px;
}
#blog-detalhe .interesse form button {
  font-family: "Encode Sans";
  font-size: 13px;
  font-weight: 800;
  color: #555555;
  background-color: #F4B344;
  border-radius: 30px;
  padding: 12px 47px;
  float: right;
  transition: 0.3s ease-in;
}
#blog-detalhe .interesse form button:hover {
  transition: 0.3s ease-in;
  color: #F4B344;
  background-color: #353434;
}
#blog-detalhe .compartilhar {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 50px;
  padding: 10px 0;
  border-bottom: solid 1px #555555;
  margin-bottom: 30px;
  transition: 0.3s ease-in;
  font-size: 16px;
  color: #555555;
}
#blog-detalhe .compartilhar a {
  text-decoration: none;
  margin: 0 10px;
  color: #555555;
}
#blog-detalhe .compartilhar a:hover {
  transition: 0.3s ease-in;
  color: #F4B344;
}

#paage-contato {
  background: url("../img/home/background-home.png") top center no-repeat;
  background-size: contain;
  position: relative;
  overflow: hidden;
}
#paage-contato::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 137px;
  background-color: #636362;
  border-radius: 0 0 0 60px;
}
#paage-contato .container-imagens {
  padding-left: 100px;
  position: relative;
  float: right;
  height: 176px;
}
@media (max-width: 991px) {
  #paage-contato .container-imagens {
    padding-left: 0;
  }
}
#paage-contato .container-imagens::after {
  content: "";
  position: absolute;
  top: 0;
  right: -2px;
  width: 100%;
  height: 137px;
  background-color: #636362;
  border-radius: 0 0 0 60px;
}
@media (max-width: 991px) {
  #paage-contato .container-imagens::after {
    display: none;
  }
}
#paage-contato .contatos {
  padding-top: 180px;
  padding-bottom: 50px;
}
#paage-contato .contatos h3 {
  font-weight: 700;
}
#paage-contato .contatos p {
  font-size: 18px;
}
#paage-contato .contato {
  padding: 90px 0 60px 0;
}
@media (max-width: 991px) {
  #paage-contato .contato {
    padding: 40px 0;
  }
}
#paage-contato .contato h2 {
  margin-top: 50px;
}

#about {
  background: url("../img/home/background-home.png") top center no-repeat;
}
#about #carouselHome {
  position: relative;
}
#about #carouselHome .carousel-item {
  width: 100%;
  height: 432px;
}
@media (max-width: 991px) {
  #about #carouselHome .carousel-item {
    height: auto;
  }
}
#about #carouselHome .carousel-item img {
  position: absolute;
  width: 100%;
  top: 0;
  right: -33.3%;
  z-index: 1;
  border-radius: 0px 0px 0px 68px;
}
@media (max-width: 991px) {
  #about #carouselHome .carousel-item img {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    right: 1;
  }
}
#about #carouselHome .carousel-item .carousel-caption {
  right: inherit;
  left: 0;
  top: 135px;
  text-align: left;
  z-index: 3;
  max-width: 310px;
}
@media (max-width: 991px) {
  #about #carouselHome .carousel-item .carousel-caption {
    position: relative;
    top: 0;
    padding-bottom: 30px;
    max-width: 100%;
  }
  #about #carouselHome .carousel-item .carousel-caption a {
    text-align: center;
  }
}
#about #carouselHome .carousel-item .carousel-caption h2 {
  font-family: "Encode Sans";
  font-size: 30.15px;
  color: #636362;
  font-weight: 800;
  margin-bottom: 13px;
}
#about #carouselHome .carousel-item .carousel-caption p {
  font-family: "Roboto Flex";
  font-size: 14px;
  color: #939292;
  margin-bottom: 21px;
  line-height: 20px;
}
#about #carouselHome .carousel-item .carousel-caption .btn-banner {
  display: inline-block;
  text-decoration: none;
  font-weight: 800;
  font-size: 12px;
  color: #555555;
  background-color: #D0D1D3;
  padding: 12px 24px;
  border-radius: 20px;
  transition: 0.3s ease-in;
}
#about #carouselHome .carousel-item .carousel-caption .btn-banner:hover {
  transition: 0.3s ease-in;
  background-color: #F4B344;
  color: #ffffff;
}
#about #carouselHome .carousel-indicators [data-bs-target] {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  opacity: 1;
  background-color: #F4B344;
}
#about #carouselHome .carousel-indicators {
  bottom: -43px;
  gap: 5px;
}
#about #carouselHome .carousel-indicators .active {
  background-color: #555555;
}
#about .destaques {
  padding: 77px 0;
  overflow: hidden;
}
@media (max-width: 991px) {
  #about .destaques {
    padding: 0;
  }
}
#about .destaques #carouselDestaque {
  position: relative;
}
#about .destaques #carouselDestaque .carousel-caption {
  position: relative;
  text-align: left;
  color: #555555;
}
@media (max-width: 991px) {
  #about .destaques #carouselDestaque .carousel-caption {
    left: 0;
    text-align: center;
  }
}
#about .destaques #carouselDestaque .carousel-caption h3 {
  font-family: "Roboto Flex";
  font-size: 14px;
  position: relative;
  margin-top: 22px;
  color: #F4B344;
}
#about .destaques #carouselDestaque .carousel-caption h3::after {
  content: "";
  position: absolute;
  left: -60px;
  top: 8px;
  width: 52px;
  height: 2.5px;
  background-color: #555555;
}
#about .destaques #carouselDestaque .carousel-caption h2 {
  font-family: "Encode Sans";
  font-size: 30px;
  font-weight: 800;
}
#about .destaques #carouselDestaque .carousel-caption p {
  font-family: "Roboto Flex";
  font-size: 14px;
  max-width: 400px;
}
#about .destaques #carouselDestaque .carousel-caption a {
  display: inline-block;
  text-decoration: none;
  font-family: "Encode Sans";
  font-size: 13px;
  color: #F4B344;
  font-weight: 800;
  padding: 12px 35px;
  border-radius: 30px;
  background-color: #555555;
  transition: 0.3s ease-in;
  margin-top: 8px;
}
#about .destaques #carouselDestaque .carousel-caption a:hover {
  transition: 0.3s ease-in;
  background-color: #353434;
}
#about .destaques #carouselDestaque .img-destaque {
  width: 100%;
  height: 352px;
  max-width: 100%;
  border-radius: 30px;
  float: right;
  margin-bottom: 50px;
}
#about .destaques #carouselDestaque .img-destaque img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
}
@media (max-width: 991px) {
  #about .destaques #carouselDestaque .carousel-item .row {
    flex-direction: column-reverse;
  }
}
#about .depoimentos {
  padding: 44px 0 55px 0;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  background: #f8f8f8;
}
#about .depoimentos h3 {
  font-family: "Roboto Flex";
  font-size: 12px;
  color: #F4B344;
  position: relative;
  text-align: center;
  padding: 8px 0;
  margin-bottom: -6px;
}
#about .depoimentos h3::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 2px;
  width: 52px;
  background-color: #F4B344;
}
#about .depoimentos h2 {
  font-family: "Encode Sans";
  font-size: 30px;
  font-weight: 800;
  color: #555555;
  text-align: center;
  margin-bottom: 30px;
}
#about .depoimentos .lista-depoimentos .depoimento {
  margin: 0 30px;
  position: relative;
  margin-bottom: 190px;
  max-width: 700px;
}
@media (max-width: 991px) {
  #about .depoimentos .lista-depoimentos .depoimento {
    margin-bottom: 0px;
  }
}
#about .depoimentos .lista-depoimentos .depoimento .img-depoimento {
  width: 100%;
  max-width: 682px;
  height: 382px;
  border-radius: 30px;
  -webkit-box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);
}
#about .depoimentos .lista-depoimentos .depoimento .img-depoimento img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
#about .depoimentos .lista-depoimentos .depoimento .texto-depoimento {
  width: 90%;
  height: 326px;
  margin: 0 auto;
  padding: 77px 40px;
  position: absolute;
  bottom: -142px;
  left: 0;
  right: 0;
  border-radius: 30px;
  -webkit-box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
  background-color: #DFDFDF;
}
@media (max-width: 991px) {
  #about .depoimentos .lista-depoimentos .depoimento .texto-depoimento {
    position: relative;
    bottom: 75px;
    height: auto;
    padding: 40px 20;
  }
}
#about .depoimentos .lista-depoimentos .depoimento .texto-depoimento p {
  font-family: "Roboto Flex";
  font-size: 20px;
  color: #000000;
  margin-bottom: 30px;
}
#about .depoimentos .lista-depoimentos .depoimento .texto-depoimento .nome {
  font-family: "Roboto";
  font-style: italic;
  font-size: 15px;
  color: #000000;
  font-weight: 700;
}
#about .depoimentos .lista-depoimentos .slick-slide {
  height: auto;
}
#about .depoimentos .lista-depoimentos .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding-left: 0;
}
#about .depoimentos .lista-depoimentos .slick-dots li {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: #F4B344;
}
#about .depoimentos .lista-depoimentos .slick-dots li.slick-active {
  background-color: #555555;
}
#about .depoimentos .lista-depoimentos .slick-dots li button {
  display: none;
}
@media (max-width: 991px) {
  #about .reverse-row {
    flex-direction: column-reverse !important;
  }
}