#blog-detalhe {
    background: url('../img/home/background-home.png') top center no-repeat;
    background-size: contain;
    position: relative;
    padding-top: 170px;

    @media(max-width: 991px) {
        padding-top: 50px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 67%;
        height: 146px;
        background-color: #636362;
        border-radius: 0 0 0 60px;

        @media(max-width: 991px) {
            display: none;
        }
    }

    h1 {
        font-weight: 800;
        font-size: 27px;
        color: #000000;
        margin-bottom: 20px;
    }

    p {
        font-family: 'Roboto Flex';
        font-size: 14px;
        color: #000000;
    }

    .valor {
        font-size: 22px;
        color: #636362;
        margin-bottom: 20px;

        span {
            font-size: 41px;
            font-weight: 600;
        }
    }

    .btns {
        a {
            display: inline-block;
            margin-bottom: 5px;
        }
    }

    .descricao-imovel {
        padding-left: 15px;
        border-left: solid 4px #F4B344;

        @media(max-width: 991px) {
            margin-bottom: 50px;
        }
    }

    h2 {
        font-size: 16px;
        color: #555555;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    ul {
        li {
            font-size: 14px;
            color: #000000;
            list-style: disc;
            margin-bottom: 5px;
        }
    }

    .img-destaque {
        width: 100%;
        height: 486px;
        margin-bottom: 15px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 60px 0 0 0;
        }
    }

    .img-diversos {
        width: 100%;
        height: 271px;
        margin-bottom: 15px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
        }
    }

    .interesse {
        background: url('../img/imovel-detalhe/bg-interesse.png') top center no-repeat;
        background-size: cover;
        padding: 50px 0;
        margin-top: 100px;

        h2 {
            font-family: 'Encode Sans';
            font-size: 26px;
            color: #ffffff;
            position: relative;
            font-weight: 800;
            margin-left: 30px;

            &::after {
                content: "";
                position: absolute;
                top: -12px;
                left: 0;
                height: 3px;
                width: 76px;
                background-color: #ffffff;
            }
        }

        p {
            font-family: 'Encode Sans';
            font-size: 22px;
            color: #ffffff;
            margin-left: 30px;
            line-height: 27px;
        }

        form {
            margin-top: 30px;

            input,
            label,
            textarea {
                width: 100%;
            }

            label {
                font-family: 'Encode Sans';
                font-weight: 600;
                font-size: 18px;
                color: #ffffff;
                margin-left: 32px;
                margin-bottom: 6px;

                @media(max-width: 991px) {
                    max-width: 100%;
                    margin-left: 0;
                }
            }

            input,
            textarea,
            input::placeholder {
                font-family: "Montserrat";
                font-size: 11px;
                color: #777777;
            }

            input,
            textarea {
                padding: 8px 32px;
                border: none;
                border-radius: 30px;
                margin-bottom: 31px;
                background-color: #FFF3EA;
            }

            textarea {
                min-height: 160px;
            }

            button {
                font-family: 'Encode Sans';
                font-size: 13px;
                font-weight: 800;
                color: #555555;
                background-color: #F4B344;
                border-radius: 30px;
                padding: 12px 47px;
                float: right;
                transition: 0.3s ease-in;

                &:hover {
                    transition: 0.3s ease-in;
                    color: #F4B344;
                    background-color: #353434;
                }
            }
        }
    }


    .compartilhar {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 50px;
        padding: 10px 0;
        border-bottom: solid 1px #555555;
        margin-bottom: 30px;
        transition: .3s ease-in;

        font-size: 16px;
        color: #555555;

        a {
            text-decoration: none;
            margin: 0 10px;
            color: #555555;
        }
        a:hover {
            transition: .3s ease-in;
            color: #F4B344;
        }
    }
}
