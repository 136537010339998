.contato {
    padding: 60px 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;

    @media(max-width: 991px) {
        background-image: none !important;
    }

    h2 {
        font-family: 'Encode Sans';
        font-size: 28px;
        color: #141414;
        position: relative;
        font-weight: 800;
        margin-left: 30px;

        &::after {
            content: "";
            position: absolute;
            top: -12px;
            left: 0;
            height: 3px;
            width: 76px;
            background-color: #141414;
        }
    }

    p {
        font-family: 'Encode Sans';
        font-size: 24px;
        color: #555555;
        margin-left: 30px;
        line-height: 27px;
    }

    form {
        margin-top: 30px;

        @media(max-width: 991px) {
            margin: 30px 30px 0 30px;
        }

        .form-control,
        label,
        textarea {
            width: 100%;
        }

        label {
            font-family: 'Encode Sans';
            font-weight: 600;
            font-size: 18px;
            color: #000000;
            margin-left: 32px;
            margin-bottom: 3px;

            @media(max-width: 991px) {
                max-width: 100%;
                margin-left: 0;
            }
        }

        .form-check {
            label {
                margin-left: 0 !important;
            }
        }

        .form-check .form-check-input {
            margin-top: 6px;
        }

        .form-control,
        textarea,
        .form-control::placeholder {
            font-family: "Montserrat";
            font-size: 13px;
            color: #777777;
        }

        .form-control,
        textarea {
            padding: 8px 32px;
            border: none;
            border-radius: 30px;
            margin-bottom: 20px;
            background-color: #FFF3EA;

            @media(max-width: 991px) {
                background-color: #e0dfdf;
            }
        }

        textarea {
            min-height: 100px;
        }

        button {
            font-family: 'Encode Sans';
            font-size: 13px;
            font-weight: 800;
            color: #F4B344;
            background-color: #555555;
            border-radius: 30px;
            padding: 12px 47px;
            float: right;
            transition: 0.3s ease-in;

            &:hover {
                transition: 0.3s ease-in;
                background-color: #353434;
            }
        }
    }
}
