#about {
    background: url('../img/home/background-home.png') top center no-repeat;

    #carouselHome {
        position: relative;

        .carousel-item {
            width: 100%;
            height: 432px;

            @media(max-width: 991px) {
                height: auto;
            }

            img {
                position: absolute;
                width: 100%;
                top: 0;
                right: -33.3%;
                z-index: 1;
                border-radius: 0px 0px 0px 68px;

                @media(max-width: 991px) {
                    position: relative;
                    width: 100%;
                    height: auto;
                    top: 0;
                    right: 1;
                }
            }

            .carousel-caption {
                right: inherit;
                left: 0;
                top: 135px;
                text-align: left;
                z-index: 3;
                max-width: 310px;

                @media(max-width: 991px) {
                    position: relative;
                    top: 0;
                    padding-bottom: 30px;
                    max-width: 100%;

                    a {
                        text-align: center;
                    }
                }

                h2 {
                    font-family: 'Encode Sans';
                    font-size: 30.15px;
                    color: #636362;
                    font-weight: 800;
                    margin-bottom: 13px;
                }

                p {
                    font-family: 'Roboto Flex';
                    font-size: 14px;
                    color: #939292;
                    margin-bottom: 21px;
                    line-height: 20px;
                }

                .btn-banner {
                    display: inline-block;
                    text-decoration: none;
                    font-weight: 800;
                    font-size: 12px;
                    color: #555555;
                    background-color: #D0D1D3;
                    padding: 12px 24px;
                    border-radius: 20px;
                    transition: .3s ease-in;

                    &:hover {
                        transition: .3s ease-in;
                        background-color: #F4B344;
                        color: #ffffff;
                    }
                }
            }
        }

        .carousel-indicators [data-bs-target] {
            width: 13px;
            height: 13px;
            border-radius: 100%;
            opacity: 1;
            background-color: #F4B344;
        }

        .carousel-indicators {
            bottom: -43px;
            gap: 5px;
        }

        .carousel-indicators .active {
            background-color: #555555;
        }
    }

    .destaques {
        padding: 77px 0;
        overflow: hidden;

        @media(max-width: 991px) {
            padding: 0;
        }

        #carouselDestaque {
            position: relative;

            .carousel-caption {
                position: relative;
                text-align: left;
                color: #555555;

                @media(max-width: 991px) {
                    left: 0;
                    text-align: center;
                }

                h3 {
                    font-family: 'Roboto Flex';
                    font-size: 14px;
                    position: relative;
                    margin-top: 22px;
                    color: #F4B344;

                    &::after {
                        content: '';
                        position: absolute;
                        left: -60px;
                        top: 8px;
                        width: 52px;
                        height: 2.5px;
                        background-color: #555555;
                    }
                }

                h2 {
                    font-family: 'Encode Sans';
                    font-size: 30px;
                    font-weight: 800;
                }

                p {
                    font-family: 'Roboto Flex';
                    font-size: 14px;
                    max-width: 400px;
                }

                a {
                    display: inline-block;
                    text-decoration: none;
                    font-family: 'Encode Sans';
                    font-size: 13px;
                    color: #F4B344;
                    font-weight: 800;
                    padding: 12px 35px;
                    border-radius: 30px;
                    background-color: #555555;
                    transition: .3s ease-in;
                    margin-top: 8px;

                    &:hover {
                        transition: .3s ease-in;
                        background-color: #353434;
                    }
                }
            }

            .img-destaque {
                width: 100%;
                height: 352px;
                max-width: 100%;
                border-radius: 30px;
                float: right;
                margin-bottom: 50px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    object-fit: cover;
                }
            }

            @media(max-width: 991px) {
                .carousel-item .row {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    .depoimentos {
        padding: 44px 0 55px 0;
        max-width: 2000px;
        margin-left: auto;
        margin-right: auto;
        background: #f8f8f8;

        h3 {
            font-family: 'Roboto Flex';
            font-size: 12px;
            color: #F4B344;
            position: relative;
            text-align: center;
            padding: 8px 0;
            margin-bottom: -6px;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                margin: 0 auto;
                height: 2px;
                width: 52px;
                background-color: #F4B344;
            }
        }

        h2 {
            font-family: 'Encode Sans';
            font-size: 30px;
            font-weight: 800;
            color: #555555;
            text-align: center;
            margin-bottom: 30px;
        }

        .lista-depoimentos {
            .depoimento {
                margin: 0 30px;
                position: relative;
                margin-bottom: 190px;
                max-width: 700px;

                @media(max-width: 991px) {
                    margin-bottom: 0px;
                }

                .img-depoimento {
                    width: 100%;
                    max-width: 682px;
                    height: 382px;
                    border-radius: 30px;
                    -webkit-box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);
                    box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 30px;
                    }
                }

                .texto-depoimento {
                    width: 90%;
                    height: 326px;
                    margin: 0 auto;
                    padding: 77px 40px;
                    position: absolute;
                    bottom: -142px;
                    left: 0;
                    right: 0;
                    border-radius: 30px;
                    -webkit-box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
                    box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
                    background-color: #DFDFDF;

                    @media(max-width: 991px) {
                        position: relative;
                        bottom: 75px;
                        height: auto;
                        padding: 40px 20;
                    }

                    p {
                        font-family: 'Roboto Flex';
                        font-size: 20px;
                        color: #000000;
                        margin-bottom: 30px;
                    }

                    .nome {
                        font-family: 'Roboto';
                        font-style: italic;
                        font-size: 15px;
                        color: #000000;
                        font-weight: 700;
                    }
                }
            }

            .slick-slide {
                height: auto;
            }

            .slick-dots {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 12px;
                padding-left: 0;

                li {
                    width: 13px;
                    height: 13px;
                    border-radius: 100%;
                    background-color: #F4B344;

                    &.slick-active {
                        background-color: #555555;
                    }

                    button {
                        display: none;
                    }
                }
            }
        }
    }

    @media(max-width: 991px) {
        .reverse-row {
            flex-direction: column-reverse !important;
        }
    }
}
