#paage-contato {
    background: url('../img/home/background-home.png') top center no-repeat;
    background-size: contain;
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 137px;
        background-color: #636362;
        border-radius: 0 0 0 60px;
    }

    // &::after {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     width: 60.2%;
    //     height: 146px;
    //     background-color: #636362;
    //     border-radius: 0 0 0 60px;

    //     @media(max-width: 991px) {
    //         display: none;
    //     }
    // }

    .container-imagens {
        padding-left: 100px;
        position: relative;
        float: right;
        height: 176px;

        @media(max-width: 991px) {
            padding-left: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: -2px;
            width: 100%;
            height: 137px;
            background-color: #636362;
            border-radius: 0 0 0 60px;

            @media(max-width: 991px) {
                display: none;
            }
        }
    }

    .contatos {
        padding-top: 180px;
        padding-bottom: 50px;

        h3 {
            font-weight: 700;
        }

        p {
            font-size: 18px;
        }
    }

    .contato {
        padding: 90px 0 60px 0;

        @media(max-width: 991px) {
            padding: 40px 0;
        }

        h2 {
            margin-top: 50px;
        }
    }
}
