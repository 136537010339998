#home {
    background: url('../img/home/background-home.png') top center no-repeat;
    background-size: contain;

    #carouselHome {
        position: relative;

        .carousel-item {
            width: 100%;
            height: 532px;

            @media(max-width: 991px) {
                height: auto;
            }

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: -33.3%;
                z-index: 1;
                border-radius: 0px 0px 0px 68px;

                @media(max-width: 991px) {
                    position: relative;
                    width: 100%;
                    height: 305px;
                    top: 0;
                    right: 1;
                }
            }

            .carousel-caption {
                right: inherit;
                left: 0;
                top: 135px;
                text-align: left;
                z-index: 3;
                max-width: 310px;

                @media(max-width: 991px) {
                    position: relative;
                    top: 0;
                    padding-bottom: 30px;
                    max-width: 100%;

                    a {
                        text-align: center;
                    }
                }

                h2 {
                    font-family: 'Encode Sans';
                    font-size: 30.15px;
                    color: #636362;
                    font-weight: 800;
                    margin-bottom: 13px;
                }

                p {
                    font-family: 'Roboto Flex';
                    font-size: 14px;
                    color: #939292;
                    margin-bottom: 21px;
                    line-height: 20px;
                }

                .btn-banner {
                    display: inline-block;
                    text-decoration: none;
                    font-weight: 800;
                    font-size: 12px;
                    color: #555555;
                    background-color: #D0D1D3;
                    padding: 12px 24px;
                    border-radius: 20px;
                    transition: .3s ease-in;

                    &:hover {
                        transition: .3s ease-in;
                        background-color: #F4B344;
                        color: #ffffff;
                    }
                }
            }
        }

        .carousel-indicators [data-bs-target] {
            width: 13px;
            height: 13px;
            border-radius: 100%;
            opacity: 1;
            background-color: #F4B344;
        }

        .carousel-indicators {
            bottom: -43px;
            gap: 5px;
        }

        .carousel-indicators .active {
            background-color: #555555;
        }
    }

    .alugar-comprar {
        margin-top: 87px;
        margin-bottom: 60px;
        max-width: 2000px;
        margin-left: auto;
        margin-right: auto;

        h3 {
            font-family: 'Roboto Flex';
            font-size: 14px;
            color: #EAAF0E;
            margin-bottom: 0;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: -60px;
                top: 8px;
                width: 52px;
                height: 2.5px;
                background-color: #EAAF0E;
            }
        }

        h2 {
            font-family: 'Encode Sans';
            font-size: 30.14px;
            color: #555555;
            font-weight: 800;
        }

        .filtros {
            display: flex;
            align-items: center;
            margin-top: 18px;
            margin-bottom: 17px;

            p {
                font-family: 'Roboto Flex';
                font-size: 14px;
                color: #000000;
                margin-bottom: 0;
                min-width: 75px;
            }
        }

        ul.filter.clearfix {
            display: flex;
            gap: 5px;

            @media(max-width: 991px) {
                max-width: 100%;
                overflow: auto;
            }

            li {
                font-family: 'Encode Sans';
                font-size: 12.82px;
                color: #555555;
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 800;
                padding: 8px 18px;
                border-radius: 30px;
                background-color: #D0D1D3;
                cursor: pointer;
                transition: .3s ease-in;

                svg {
                    width: 25px;
                    height: 25px;
                }

                &:hover,
                &.active {
                    background-color: #555555;
                    color: #F4B344;
                    transition: .3s ease-in;

                    svg path {
                        fill: #F4B344;
                    }
                }
            }
        }

        .slider a {
            display: inline-block;
            text-decoration: none;
            width: 100%;
        }

        .slider .itens {
            max-width: 310px;
            height: auto;
        }

        .card-movel {
            margin: 0 5px;

            .img-card {
                width: 100%;
                max-width: 300px;
                height: 340px;
                border-radius: 30px;
                position: relative;

                @media(max-width: 991px) {
                    max-width: 240px;
                    height: 240px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 30px;
                }

                .layer {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    background-color: #1a1a1aa6;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .3s ease-in;
                    opacity: 0;

                    img {
                        width: 57px;
                        height: 57px;
                        border-radius: 0;
                    }
                }
            }

            &:hover {
                transition: .3s ease-in;

                .layer {
                    transition: .3s ease-in;
                    opacity: 1;
                }
            }

            h3 {
                font-family: 'Encode Sans';
                font-size: 20px;
                color: #555555;
                font-weight: 800;
                margin: 10px 0;

                &::after {
                    display: none;
                }
            }

            .tag {
                font-family: 'Encode Sans';
                font-size: 16px;
                color: #555555;
                font-weight: 800;
                display: inline-block;
                padding: 2px 15px;
                border-radius: 30px;
                color: #ffffff;
            }

            .preco {
                font-family: 'Encode Sans';
                font-size: 16px;
                color: #555555;
                margin: 6px 8px;
            }
        }

        .moveis {
            // max-width: 80.6%;
            // float: right;

            // @media(max-width: 991px) {
            //     max-width: 90.6%;
            // }
        }
    }

    .buscar {
        padding: 69px 0 217px 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #F3BD12;

        h3 {
            font-family: 'Roboto Flex';
            font-size: 14px;
            color: #ffffff;
            position: relative;

            @media(max-width: 991px) {
                text-align: center;
                color: #000000;
            }

            &::after {
                content: '';
                position: absolute;
                left: -60px;
                top: 8px;
                width: 52px;
                height: 2.5px;
                background-color: #ffffff;
            }
        }

        h2 {
            font-family: 'Encode Sans';
            font-weight: 800;
            color: #ffffff;
            font-size: 30px;
            margin-bottom: 6px;

            @media(max-width: 991px) {
                text-align: center;
                color: #000000;
            }
        }

        p {
            font-family: 'Roboto Flex';
            font-size: 14px;
            color: #ffffff;

            @media(max-width: 991px) {
                text-align: center;
                color: #000000;
            }
        }

        form {
            position: relative;
            max-width: 719px;
            margin: 0 auto;

            input {
                width: 100%;
                max-width: 719px;
                padding: 17px 15px;
                margin: 0 auto;
                display: flex;
                position: absolute;
                top: 28px;
                left: 0;
                right: 0;
                border-radius: 30px;
                border: none;
            }

            .select2-container--default .select2-selection--single,
            input,
            input::placeholder {
                font-family: 'Roboto Flex';
                font-size: 14px;
                color: #555555;
                border: none;
            }

            .select2-selection__arrow {
                display: none;
            }

            button {
                font-family: 'Encode Sans';
                font-size: 13px;
                font-weight: 800;
                color: #F4B344;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 5px;
                top: 33px;
                gap: 10px;
                padding: 8px 28px;
                border-radius: 30px;
                background-color: #555555;
                transition: .3s ease-in;

                @media(max-width: 991px) {
                    font-size: 13px;
                    padding: 8px;
                }

                img {
                    width: 29px;
                    height: 29px;
                }

                &:hover {
                    transition: .3s ease-in;
                    background-color: #353434;
                }
            }

            .select2-container {
                width: 100% !important;
                position: absolute;
                top: 28px;
                left: 0;
                right: 0;
                padding: 13px 15px;
                background: #fff;
                border-radius: 30px;
            }

            @media(max-width: 991px) {
                .select2-container--default .select2-selection--single .select2-selection__rendered {
                    max-width: 60% !important;
                }
            }
        }
    }

    .destaques {
        padding: 77px 0;
        overflow: hidden;

        #carouselDestaque {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: -34px;
                right: -23px;
                width: 72px;
                height: 248px;
                background: url('../img/home/elemento1.png') center center no-repeat;
                background-size: cover;
                z-index: -1;
            }

            &::before {
                content: '';
                position: absolute;
                bottom: -35px;
                right: -129px;
                width: 386px;
                height: 119px;
                background: url('../img/home/elemento2.png') center center no-repeat;
                background-size: cover;
                z-index: -1;

                @media(max-width: 991px) {
                    bottom: 190px;
                }
            }

            .carousel-caption {
                position: relative;
                text-align: left;
                color: #555555;

                h3 {
                    font-family: 'Roboto Flex';
                    font-size: 14px;
                    position: relative;
                    margin-top: 22px;

                    &::after {
                        content: '';
                        position: absolute;
                        left: -60px;
                        top: 8px;
                        width: 52px;
                        height: 2.5px;
                        background-color: #555555;
                    }
                }

                h2 {
                    font-family: 'Encode Sans';
                    font-size: 30px;
                    font-weight: 800;
                }

                p {
                    font-family: 'Roboto Flex';
                    font-size: 14px;
                    max-width: 400px;
                }

                a {
                    display: inline-block;
                    text-decoration: none;
                    font-family: 'Encode Sans';
                    font-size: 13px;
                    color: #F4B344;
                    font-weight: 800;
                    padding: 12px 35px;
                    border-radius: 30px;
                    background-color: #555555;
                    transition: .3s ease-in;
                    margin-top: 8px;

                    &:hover {
                        transition: .3s ease-in;
                        background-color: #353434;
                    }
                }
            }

            .carousel-indicators [data-bs-target] {
                width: 13px;
                height: 13px;
                border-radius: 100%;
                opacity: 1;
                background-color: #F4B344;
            }

            .carousel-indicators {
                bottom: 58px;
                gap: 5px;
                right: inherit;
                left: -68px;

                @media(max-width: 991px) {
                    bottom: -44px;
                    gap: 5px;
                    right: 0;
                    left: 0;
                }
            }

            .carousel-indicators .active {
                background-color: #555555;
            }

            .img-destaque {
                width: 100%;
                height: 352px;
                max-width: 508px;
                border-radius: 30px;
                float: right;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    object-fit: cover;
                }
            }

            @media(max-width: 991px) {
                .carousel-item .row {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    .depoimentos {
        padding: 44px 0 55px 0;
        max-width: 2000px;
        margin-left: auto;
        margin-right: auto;

        h3 {
            font-family: 'Roboto Flex';
            font-size: 14px;
            color: #F4B344;
            position: relative;
            text-align: center;
            padding: 8px 0;
            margin-bottom: -6px;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                margin: 0 auto;
                height: 2px;
                width: 52px;
                background-color: #F4B344;
            }
        }

        h2 {
            font-family: 'Encode Sans';
            font-size: 30px;
            font-weight: 800;
            color: #555555;
            text-align: center;
            margin-bottom: 30px;
        }

        .lista-depoimentos {
            .depoimento {
                margin: 0 30px;
                position: relative;
                margin-bottom: 190px;
                max-width: 700px;

                @media(max-width: 991px) {
                    margin-bottom: 0px;
                }

                .img-depoimento {
                    width: 100%;
                    max-width: 682px;
                    height: 382px;
                    border-radius: 30px;
                    -webkit-box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);
                    box-shadow: 2px 5px 5px 4px rgba(0, 0, 0, 0.25);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 30px;
                    }
                }

                .texto-depoimento {
                    width: 90%;
                    height: 326px;
                    margin: 0 auto;
                    padding: 77px 40px;
                    position: absolute;
                    bottom: -142px;
                    left: 0;
                    right: 0;
                    border-radius: 30px;
                    -webkit-box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
                    box-shadow: 1px 3px 5px 4px rgba(0, 0, 0, 0.15);
                    background-color: #DFDFDF;

                    @media(max-width: 991px) {
                        position: relative;
                        bottom: 75px;
                        height: auto;
                        padding: 40px 20;
                    }

                    p {
                        font-family: 'Roboto Flex';
                        font-size: 20px;
                        color: #000000;
                        margin-bottom: 30px;
                    }

                    .nome {
                        font-family: 'Roboto';
                        font-style: italic;
                        font-size: 15px;
                        color: #000000;
                        font-weight: 700;
                    }
                }
            }

            .slick-slide {
                height: auto;
            }

            .slick-dots {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 12px;
                padding-left: 0;

                li {
                    width: 13px;
                    height: 13px;
                    border-radius: 100%;
                    background-color: #F4B344;

                    &.slick-active {
                        background-color: #555555;
                    }

                    button {
                        display: none;
                    }
                }
            }
        }
    }
}
