footer {
    padding: 73px 0;
    background-color: #E0E0E0;

    @media(max-width: 991px) {
        padding: 50px 0;
        text-align: center;
    }

    .logo-footer {
        max-width: 140px;

        @media(max-width: 991px) {
            margin-bottom: 30px;
        }
    }

    .parte1 {
        padding-bottom: 25px;
    }

    .contatos {
        display: grid;
        gap: 12px;

        @media(max-width: 991px) {
            margin-bottom: 30px;
        }


        a {
            display: inline-block;
            text-decoration: none;
            font-family: 'Encode Sans';
            font-size: 14px;
            color: #555555;
            font-weight: 400;

            transition: .3s ease-in;

            &:hover {
                transition: .3s ease-in;
                color: #000000;

            }
        }
    }

    .links {
        display: grid;
        gap: 12px;

        @media(max-width: 991px) {
            margin-bottom: 30px;
        }

        a {
            display: inline-block;
            text-decoration: none;
            font-family: 'Encode Sans';
            font-size: 16px;
            color: #555555;
            font-weight: 500;
            transition: .3s ease-in;

            &:hover {
                transition: .3s ease-in;
                color: #F4B344;

            }
        }
    }

    p {
        font-family: 'Encode Sans';
        font-size: 14px;
        color: #555555;
    }
}
