#lancamentos {
    background: url('../img/home/background-home.png') top center no-repeat;
    background-size: contain;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 137px;
        background-color: #636362;
        border-radius: 0 0 0 60px;
    }

    @media(max-width: 991px) {
        padding-top: 50px;
    }

    .container-imagens {
        padding-left: 100px;
        position: relative;
        float: right;
        height: 176px;

        @media(max-width: 991px) {
            padding-left: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: -2px;
            width: 100%;
            height: 137px;
            background-color: #636362;
            border-radius: 0 0 0 60px;

            @media(max-width: 991px) {
                display: none;
            }
        }
    }

    .destaques {
        padding: 77px 0;
        overflow: hidden;
        clear: both;

        @media(max-width: 991px) {
            padding: 0;
        }

        #carouselDestaque {
            position: relative;

            .carousel-caption {
                position: relative;
                text-align: left;
                color: #555555;
                left: 0;

                @media(max-width: 991px) {
                    left: 0;
                }

                h3 {
                    font-family: 'Roboto Flex';
                    font-size: 14px;
                    position: relative;
                    margin-top: 22px;

                    &::after {
                        content: '';
                        position: absolute;
                        left: -60px;
                        top: 8px;
                        width: 52px;
                        height: 2.5px;
                        background-color: #555555;
                    }
                }

                h2 {
                    font-family: 'Encode Sans';
                    font-size: 30px;
                    font-weight: 800;
                }

                p {
                    font-family: 'Roboto Flex';
                    font-size: 14px;
                    max-width: 400px;
                }

                a {
                    display: inline-block;
                    text-decoration: none;
                    font-family: 'Encode Sans';
                    font-size: 13px;
                    color: #F4B344;
                    font-weight: 800;
                    padding: 12px 35px;
                    border-radius: 30px;
                    background-color: #555555;
                    transition: .3s ease-in;
                    margin-top: 8px;

                    &:hover {
                        transition: .3s ease-in;
                        background-color: #353434;
                    }
                }
            }

            .img-destaque {
                width: 100%;
                height: 352px;
                max-width: 100%;
                border-radius: 30px;
                float: right;
                margin-bottom: 50px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    object-fit: cover;
                }
            }

            @media(max-width: 991px) {
                .carousel-item .row {
                    flex-direction: column-reverse;
                }
            }
        }
    }
}
